import React, { useState } from 'react'

import Select from 'react-select'
import { PANEL_SORT_TYPE } from 'components/player/CommentsPanel'

const sortStyles = {
    control: (base) => ({
        ...base,
        backgroundColor: '#404040'
    }),
    option: (styles, { isFocused }) => ({
        ...styles,
        backgroundColor: isFocused ? '#404040' + '50' : '#404040',
        color: '#ffffff',
    }),
    placeholder: (styles) => ({
        ...styles,
        color: '#ffffff'
    }),
    singleValue: (styles) => ({
        ...styles,
        color: '#ffffff',
    }),
    dropdownIndicator: (styles) => ({
        ...styles,
        color: '#ffffff',
        '&:hover': {
            color: '#2ba6cb'
        }
    }),
    menu: (styles) => ({
        ...styles,
        backgroundColor: '#333333',
    }),
    clearIndicator: (styles) => ({
        ...styles,
        color: '#ffffff',
        '&:hover': {
            color: '#2ba6cb'
        }
    }),
}

const filterStyles = {
    control: (base) => ({
        ...base,
        backgroundColor: '#404040'
    }),
    option: (styles, { data, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isSelected
                    ? data.color
                    : isFocused
                        ? data.color + '50'
                        : '#404040',
            color: data.color,
            cursor : 'default',

            ':active': {
                ...styles[':active'],
                backgroundColor: isSelected
                        ? data.color
                        : data.color + '80',
            },
        };
    },
    placeholder: (styles) => ({
        ...styles,
        color: '#ffffff'
    }),
    multiValue: (styles, { data }) => {
        return {
            ...styles,
            backgroundColor: data.color + '70'
        };
    },
    multiValueLabel: (styles) => ({
        ...styles,
        color: '#ffffff',
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: '#fffff',
        ':hover': {
            backgroundColor: data.color,
            color: 'white',
        },
    }),
    menu: (styles) => ({
        ...styles,
        backgroundColor: '#333333',
    }),
    dropdownIndicator: (styles) => ({
        ...styles,
        color: '#ffffff',
        '&:hover': {
            color: '#2ba6cb'
        }
    }),
    clearIndicator: (styles) => ({
        ...styles,
        color: '#ffffff',
        '&:hover': {
            color: '#2ba6cb'
        }
    }),
    input: (styles) => ({
        ...styles,
        color: '#ffffff',
    })
}
export default ({
                    onArtistCommentsOnlyChange,
                    isArtistCommentsOnly,
                    onCommentsFilterStemIdsChange,
                    commentsFilterStemIds,
                    panelSortType,
                    updateCommentPanelSortType,
                    stems
}) => {
    const [filterSelected, setFilterSelected] = useState(isArtistCommentsOnly || (Array.isArray(commentsFilterStemIds) && commentsFilterStemIds.length !== 0))
    const [sortSelected, setSortSelected] = useState(false)

    const filterOptions = stems.map((stem) => {
        return {
            value: stem.key,
            label: stem.name,
            color: stem.bgColor,
            stemId: stem.id,
        }
    })

    const sortOptions = [{
        value: PANEL_SORT_TYPE.RECENT,
        label: 'Recent',
    }, {
        value: PANEL_SORT_TYPE.MOST_POPULAR,
        label: 'Most popular',
    }, {
        value: PANEL_SORT_TYPE.SONG_ORDER,
        label: 'Song order',
    }]

    return (
        <div className='flex-grow-0 mt-2'>
            <div className='d-flex flex-grow-0 justify-content-between mb-2'>
                <div>
                    <a className='link white-text' onClick={() => {
                        setFilterSelected(!filterSelected)
                        setSortSelected(false)
                    }}>
                        <i className="fa fa-filter left-align" />&nbsp;filter
                    </a>
                </div>
                <a className='link white-text' onClick={() => {
                    setSortSelected(!sortSelected)
                    setFilterSelected(false)
                }}>
                    sort&nbsp;<i className="fa fa-sort right-align" />
                </a>
            </div>
            {(filterSelected) && (
                <div>
                    <div className="flex-grow-0 form-check mb-2">
                        <input
                            name="is_artist_comments_only"
                            type="checkbox"
                            className="form-check-input"
                            checked={isArtistCommentsOnly}
                            onChange={(e) => onArtistCommentsOnlyChange(e.target.checked)}
                        />
                        <label className="form-check-label" htmlFor="is_artist_comments_only">
                            Only Show Artist Comments
                        </label>
                    </div>
                    <Select
                        options={filterOptions}
                        closeMenuOnSelect={false}
                        isMulti
                        placeholder="Filter by stem"
                        className="flex-grow-0 mb-3"
                        styles={filterStyles}
                        isSearchable={false}
                        onChange={(c) => { onCommentsFilterStemIdsChange(c.map((filter) => { return filter.stemId }))} }
                        defaultValue={
                            Array.isArray(commentsFilterStemIds) ? filterOptions.filter((filter) => commentsFilterStemIds.includes(filter.stemId)) : []
                    }
                    />
                </div>

            )}
            {(sortSelected) && (
                <div className="flex-grow-0 mb-2">
                    <Select
                        options={sortOptions}
                        styles={sortStyles}
                        closeMenuOnSelect={true}
                        defaultValue={sortOptions.find(el => el.value === panelSortType)}
                        placeholder="Sort by"
                        className="flex-grow-0"
                        isSearchable={false}
                        onChange={(c) => { updateCommentPanelSortType(c.value)} }
                    />
                </div>
            )}
        </div>
    )
}
