import React from 'react'
import 'whatwg-fetch'

import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { apiMiddleware } from 'redux-api-middleware'
import logMiddleware from 'middleware/logMiddleware'

import appReducer from 'reducers'

import ErrorHandler from 'components/ErrorHandler'
import PlayerApp from 'components/player/PlayerApp'


let middleware = applyMiddleware(apiMiddleware)
if (window.isDevelopment) {
  middleware = applyMiddleware(apiMiddleware, logMiddleware)
}

const store = createStore(appReducer, {}, middleware)

export default (props) => (
  <Provider store={store}>
    <ErrorHandler>
      <PlayerApp {...props} />
    </ErrorHandler>
  </Provider>
)
