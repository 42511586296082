import React, { useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import classnames from 'classnames'
import Linkify from 'react-linkify'

import { secondsToMinutes } from 'utils/time'

const COMMENT_PREROLL = 3 // seconds

export default React.forwardRef(({
  comment,
  stem,
  isHighlighted,
  isEditable,
  isDeletable,
  isLoading,
  isCommentsDisabled,
  hideStemDetails,
  onCommentListenClick,
  onCommentListenSoloClick,
  onCommentReplies,
  onCommentLike,
  onCommentUnlike,
  onCommentEdit,
  onCommentDelete,
  onHoverComment,
  onExitHoverComment
}, ref) => {
  if (_.isNil(comment)) { return null }

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const onLikeButton = () => {
    if (comment.isLiked) {
      onCommentUnlike()
    } else {
      onCommentLike()
    }
  }

  const onHover = () => { if (!_.isNil(onHoverComment)) { onHoverComment() } }
  const onExitHover = () => { if (!_.isNil(onExitHoverComment)) { onExitHoverComment() } }

  return (
    <div
      ref={ref}
      className={classnames('item d-flex flex-column', { highlight: isHighlighted })}
      onMouseEnter={onHover}
      onMouseLeave={onExitHover}
    >
      <div className='d-flex flex-grow-0 flex-row flex-wrap header' style={{backgroundColor: `${stem.bgColor}55`}}>
        {comment.isOwnerComment && (
          <div className='flex-grow-0 flex-shrink-0'>
            <span className='artist-check'>
              <i className='fa fa-check-circle'></i>&nbsp;
            </span>
          </div>
        )}
        <div className='flex-grow-0 flex-shrink-0'>
          {comment.username ? (
            <span>@{comment.username}</span>
          ) : <span>Anonymous</span>}
        </div>

        {!hideStemDetails && (
          <div className='flex-grow-1 flex-shrink-0 text-right'>
            {!_.isNil(_.get(stem, 'name')) && (
              <>
                <strong>{_.get(stem, 'name', '')}</strong>&nbsp;/&nbsp;
              </>
            )}
              <strong>{secondsToMinutes(comment.stemTime)}</strong>
          </div>
        )}
      </div>
      <div className='flex-grow-1 message'>
        <Linkify properties={{ target: "_blank" }} className='prewrap break-word'>{comment.message}</Linkify>
      </div>

      <div className='footer flex-grow-0 flex-shrink-0'>
        {(showDeleteConfirmation && !_.isNil(onCommentDelete) && isDeletable) ? (
          <div className='text-right me-2'>
            <a className='link white-text' onClick={() => setShowDeleteConfirmation(false)}>cancel</a>
            &nbsp;/&nbsp;
            {isLoading ? (<span>...</span>) : (
              <a className='link red-text' onClick={() => onCommentDelete()}>really delete?</a>
            )}
          </div>
        ) : (
          <div className='text-right me-2'>
            {isEditable && !_.isNil(onCommentEdit) && (
              <>
                {isLoading ? (<span>edit</span>) : (
                  <a className='link white-text' onClick={() => onCommentEdit()}>edit</a>
                )}
              </>
            )}
            {isDeletable && !_.isNil(onCommentDelete) && (
              <>
                {isEditable && (
                  <>&nbsp;/&nbsp;</>
                )}
                <a className='link red-text' onClick={() => setShowDeleteConfirmation(true)}>delete</a>
              </>
            )}
          </div>
        )}

        <div className='d-flex flex-row'>
          <div className='flex-grow-0 flex-shrink-0 timestamp'>
            <i>{moment(comment.createdAt).fromNow()}</i>
          </div>
          <div className='flex-grow-1 flex-shrink-1 text-right'>
            <a className='link white-text' onClick={() => onCommentListenSoloClick(stem.id, _.max([0, (comment.stemTime - COMMENT_PREROLL)]))}>
              <i className='fa fa-volume-up'></i>&nbsp;solo
            </a>&nbsp;/&nbsp;
            <a className='link white-text' onClick={() => onCommentListenClick(_.max([0, (comment.stemTime - COMMENT_PREROLL)]))}>
              <i className='fa fa-volume-up'></i>&nbsp;full
            </a>&nbsp;/&nbsp;
            {isLoading ? (<span>{comment.numLikes}&nbsp;<i className='fa fa-heart'></i></span>) : (
              <a className={classnames('link white-text', { liked: comment.isLiked })} onClick={() => onLikeButton()}>
                {comment.numLikes}&nbsp;<i className='fa fa-heart'></i>
              </a>
            )}
            {((!_.isNil(onCommentReplies) && !isCommentsDisabled) || comment.numReplies > 0) && (
              <>
                &nbsp;/&nbsp;
                <a className='link white-text' onClick={() => onCommentReplies()}>
                  {comment.numReplies > 0 ? (
                    <>
                      {comment.numReplies}&nbsp;
                      {comment.numReplies > 1 ? 'replies' : 'reply'}
                      {!isCommentsDisabled && (
                        <>
                          &nbsp;<i className='fa fa-reply'></i>
                        </>
                      )}
                    </>
                  ) : isCommentsDisabled ? null : (
                    <>reply&nbsp;<i className='fa fa-reply'></i></>
                  )}
                </a>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
})
