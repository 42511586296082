import React from 'react'
import _ from 'lodash'
import classnames from 'classnames'
import moment from 'moment'
import 'whatwg-fetch'
import { normalize, schema } from 'normalizr'
import $ from 'jquery'

import Stem from 'components/player/Stem'
import InfoOverlay from 'components/player/InfoOverlay'
import MobileOverlay from 'components/player/MobileOverlay'
import DescriptionOverlay from 'components/player/DescriptionOverlay'
import SettingsOverlay from 'components/player/SettingsOverlay'
import LoopControlsOverlay from 'components/player/LoopControlsOverlay'
import CommentsPanel from 'components/player/CommentsPanel'

import { PANEL_TYPE, PANEL_SORT_TYPE } from 'components/player/CommentsPanel'
import { secondsToMinutes } from 'utils/time'
import { fetchPost } from 'utils/request'
import { setUrlParams, getStaticAsset, doesSongTypeMatch } from 'utils'
import { LoudnessMeter } from '@domchristie/needles'

import { SONG_TYPES } from 'static'

import {
  analytics,
  googleTagPushDataLayer,
  googleTagPushEvent,
  fullStoryEvent,
  fullStorySetUserVars,
  EVENT_TYPES,
  GOOGLE_TAG_EVENTS
} from 'utils/analytics'

const COMMENT_SCHEMA = new schema.Entity('comments', {
  comment_replies: [new schema.Entity('comments')]
})

export const TRACK_HEADER_WIDTH = 80
export const COMMENTS_PANEL_WIDTH = 320
export const MOBILE_BREAKPOINT = 640
const MOBILE_AUTOPLAY_TIMEOUT = 9 * 1000 // 9s (9 * 1000ms)
const BLANK_EVENT_INTERVAL_TIME = 3 * 60 * 1000 // 3min (3m * 60s * 1000ms)
const CLOCK_INTERVAL = 200 // ms
const LINK_COPIED_TIMEOUT = 3000 // ms
const OVERLAY_FADE_TIME = 200 // ms
const COMMENTS_RELOAD_TIME = 15 * 1000 // 15sec (15s * 1000ms)
const MAX_SPEED = 2.0
const MIN_SPEED = 0.5
const MAX_VOLUME = 1.0
const MIN_VOLUME = 0.0
const audioContext = new(window.AudioContext || window.webkitAudioContext)()
const OfflineAudioContext = window.OfflineAudioContext || window.webkitOfflineAudioContext

let blankEventInterval = null
let commentTimeClickAutoplayTimeout = null

const OVERLAY_TYPE = {
  NO_STEMS: 'no-stems',
  MOBILE: 'mobile',
  SHARE_LINK: 'share-link',
  COMMENTS: 'comments',
  HELP: 'help',
  SETTINGS: 'settings',
  SONG_INFO: 'song-info',
  STEM_INFO: 'stem-info',
  LOOP_CONTROLS: 'loop-controls',
}

const defaultState = {
  lastTimestamp: audioContext.currentTime,
  isPlaying: false,
  songLength: 0.0,
  currentTime: 0.0,
  volume: 1.0,
  isVolumeNormalized: true,
  clientWidth: document.documentElement.clientWidth,

  isSetLoopStart: false,
  isSetLoopEnd: false,
  isLoopingOn: false,
  loopStart: null,
  loopEnd: null,
  userTempo: 0,
  loopLengthBeats: 0,

  speed: 1.0,
  isVarispeedEnabled: false,

  showOverlayType: null,
  isOverlayFadedOut: true,
  infoOverlayStemId: null,

  showCommentsPanel: false,
  isCommentsLoading: false,
  isFirstCommentsLoad: true,
  commentsLastTimestamp: null,
  commentsNumPages: 0,
  commentsPage: 0,
  isArtistCommentsOnly: false,
  isHideAllComments: false,
  isAddCommentState: false,
  commentPanelType: PANEL_TYPE.COMMENTS,
  commentPanelSortType: PANEL_SORT_TYPE.SONG_ORDER,
  addCommentTime: null,
  addCommentStemId: null,
  showCommentIds: [],
  highlightCommentId: null,
  comments: {},
  commentsFilterStemIds: [],

  currentUser: {},
}

export default class PlayerApp extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      ...defaultState,
      stems: props.stems || [],
      currentUser: props.currentUser || {},
      userTempo: props.song.tempo,
      isVolumeNormalized: doesSongTypeMatch(_.get(props, 'song.songType'), SONG_TYPES.MIX_COMPARISON),
    }
    this.shareLinkInput = null
    this.loadCommentsTimeout = null

    fullStorySetUserVars(_.get(props, 'currentUser'))
  }

  componentDidMount() {
    const propStems = _.get(this, 'props.stems', [])
    if (propStems.length > 0) {
      if (window.isMobile) {
        this.showOverlay(OVERLAY_TYPE.MOBILE)
      } else {
        this.loadNextStem()
      }
    } else {
      this.showOverlay(OVERLAY_TYPE.NO_STEMS)
    }
    document.addEventListener('keydown', (e) => this.parseKeyDown(e))
    window.addEventListener('resize', (e) => this.onResize(e))


    this.logEvent(EVENT_TYPES.PAGE_LOADED)
    googleTagPushEvent(GOOGLE_TAG_EVENTS.PAGE_LOADED)
    fullStoryEvent(GOOGLE_TAG_EVENTS.PAGE_LOADED)
    this.blankEventInterval = setInterval(() => {
      this.logEvent(EVENT_TYPES.BLANK_EVENT)
      googleTagPushEvent(GOOGLE_TAG_EVENTS.BLANK_EVENT)
    }, BLANK_EVENT_INTERVAL_TIME)
  }

  componentWillUnmount() {
    if (this.loadCommentsTimeout) { clearTimeout(this.loadCommentsTimeout) }
    document.removeEventListener('keydown', (e) => this.parseKeyDown(e))
    window.removeEventListener('resize', (e) => this.onResize(e))
    if (this.blankEventInterval) { clearInterval(blankEventInterval) }
  }

  setStemState(id, updates, callback) {
    const updateStem = _.find(this.state.stems, { id })
    const otherStems = _.filter(this.state.stems, (stem) => (stem.id !== id))
    this.setState({
      stems: [
        ...otherStems,
        { ...updateStem, ...updates },
      ]
    }, () => {
      if (!_.isNil(callback)) { callback() }
    })
  }

  updateCommentPanelType(panelType, callback) {
    this.setState({ commentPanelType: panelType }, () => {
      if (!_.isNil(callback)) { callback() }
    })
  }

  updateCommentPanelSortType(sortType, callback) {
    this.setState({ commentPanelSortType: sortType }, () => {
      if (!_.isNil(callback)) { callback() }
    })
  }

  isSongType(songType) {
    return doesSongTypeMatch(_.get(this.props, 'song.songType'), songType)
  }

  isCommentsViewable() {
    return !this.state.isHideAllComments && !_.get(this.props, 'song.isCommentsDisabled')
  }

  isCommentsDisabled() {
    return this.isCommentsDisabledLocally() ||
      _.get(this.props, 'song.isCommentsDisabled') ||
      (
        _.get(this.props, 'song.isArtistCommentsOnly') &&
        _.get(this.state, 'currentUser.artistId', null) !== this.props.song.artistId
      )
  }

  isCommentsDisabledLocally() {
    return this.state.isHideAllComments || (
      this.state.isArtistCommentsOnly &&
      _.get(this.state, 'currentUser.artistId') !== this.props.song.artistId
    )
  }

  isSoloActive() {
    return _.reduce(this.state.stems, (memo, stem) => memo || stem.isSoloed, false)
  }

  decibelsToGain(decibels) {
    return Math.pow(10, (decibels / 20))
  }

  getStemGain(stem) {
    if (this.state.isVolumeNormalized) {
      return stem.normalizedGain * this.state.volume
    } else {
      return this.state.volume
    }
  }

  setStemGainNode(stem, gainNode) {
    if (this.isSoloActive()) {
      if (stem.isSoloed) {
        gainNode.gain.value = this.getStemGain(stem)
      } else {
        gainNode.gain.value = 0.0
      }
    } else {
      if (stem.isMuted) {
        gainNode.gain.value = 0.0
      } else {
        gainNode.gain.value = this.getStemGain(stem)
      }
    }
  }

  setStemGain(stemId) {
    const stem = _.find(this.state.stems, { id: stemId })
    if (_.isNil(stem) || _.isNil(stem.gain)) { return null }
    this.setStemGainNode(stem, stem.gain)
  }

  setAllStemGain() {
    _.forEach(this.state.stems, (stem) => this.setStemGain(stem.id))
  }

  setSongSpeed() {
    _.forEach(this.state.stems, (stem) => {
      if (_.isNil(stem.source)) { return null }
      stem.source.playbackRate.value = this.state.speed
    })
    if (this.state.isPlaying) { this.pause(() => this.play()) }
  }

  onVolumeChange(volume) {
    const parsedVolume = parseFloat(volume) / 100.0
    const safeVolume = _.max([_.min([parsedVolume, MAX_VOLUME]), MIN_VOLUME])
    this.setState({ volume: safeVolume }, () => this.setAllStemGain())
  }

  onVolumeNormalizeChange(value) {
    this.setState({ isVolumeNormalized: value }, () => this.setAllStemGain())
  }

  onSpeedChange(speed) {
    const parsedSpeed = parseFloat(speed) / 100.0
    const safeSpeed = _.max([_.min([parsedSpeed, MAX_SPEED]), MIN_SPEED])
    this.setState({
      isVarispeedEnabled: parsedSpeed !== 1.0,
      speed: safeSpeed,
    }, () => this.setSongSpeed())
  }

  onSpeedReset() {
    this.setState({
      isVarispeedEnabled: false,
      speed: defaultState.speed,
    }, () => this.setSongSpeed())
  }

  /*******************************
  * FILE LOADING FUNCTIONS
  ********************************/
  isAllStemsLoaded() {
    return _.reduce(this.state.stems, (memo, stem) => (memo && stem.isLoaded), true)
  }

  isAnyStemsLoaded() {
    return _.reduce(this.state.stems, (memo, stem) => (memo || stem.isLoaded), false)
  }

  requestAudioFile(url, callback, errCallback) {
    fetch(url).then((res) => {
      return res.arrayBuffer()
    }).then((buffer) => {
      audioContext.decodeAudioData(buffer, (b) => {
        callback(b)
      }, (error) => {
        errCallback()
        fetchPost('/api/client_error/', 'POST', {
          user_id: _.get(this, 'state.currentUser.id'),
          message: `[PlayerApp] - requestAudioFile Error: ${_.get(error, 'message')}`,
        })
      })
    }).catch((error) => {
      errCallback()
      fetchPost('/api/client_error/', 'POST', {
        user_id: _.get(this, 'state.currentUser.id'),
        message: `[PlayerApp] - requestAudioFile Error: ${_.get(error, 'message')}`,
      })
    })
  }

  loadNextStem() {
    const notLoadedStems = _.filter(this.state.stems, (stem) => !stem.isLoaded)
    this.loadStem(_.first(notLoadedStems))
  }

  loadStem(stem) {
    if (!stem) { return null }

    const isFirstLoaded = _.filter(this.state.stems, (stem) => stem.isLoaded).length <= 0
    this.requestAudioFile(stem.audioUrl, (buffer) => {

      this.analyzeLoudnessLufs(stem.id, buffer)

      if (this.state.isPlaying) {
        this.pause(() => this.setStemState(stem.id, {
          buffer: buffer,
          audioLength: buffer.duration,
          isLoaded: true,
          isMuted: stem.isMutedByDefault && this.isSongType(SONG_TYPES.STEM_PLAYER),
          isSoloed: isFirstLoaded && this.isSongType(SONG_TYPES.MIX_COMPARISON),
        }, () => this.play(() => this.stemLoadComplete(stem))))
      } else {
        this.setStemState(stem.id, {
          buffer: buffer,
          audioLength: buffer.duration,
          isLoaded: true,
          isMuted: stem.isMutedByDefault && this.isSongType(SONG_TYPES.STEM_PLAYER),
          isSoloed: isFirstLoaded && this.isSongType(SONG_TYPES.MIX_COMPARISON),
        }, () => this.stemLoadComplete(stem))
      }
    }, () => {
      this.setStemState(stem.id, {
        isError: true,
        isLoaded: true,
      }, () => this.stemLoadComplete(stem))
    })
  }

  reloadStem(stemId) {
    const stem = _.find(this.state.stems, { id: stemId })
    if (_.isNil(stem)) { return null }

    this.pause(() => {
      this.setStemState(stemId, {
        isLoaded: false,
        isError: false,
      }, () => {
        this.showOverlay(OVERLAY_TYPE.LOADING, () => {
          this.requestAudioFile(stem.audioUrl, (buffer) => {
            this.analyzeLoudnessLufs(stem.id, buffer)
            this.setStemState(stem.id, {
              buffer: buffer,
              audioLength: buffer.duration,
              isLoaded: true,
            }, () => {
              this.hideOverlay(() => {
                this.setState({
                  songLength: _.max(_.map(this.state.stems, (stem) => stem.audioLength)),
                })
              })
            })
          }, () => {
            this.setStemState(stem.id, {
              isError: true,
              isLoaded: true,
            }, () => this.hideOverlay())
          })
        })
      })
    })
  }

  stemLoadComplete(stem) {
    if (this.isAllStemsLoaded()) {
      this.setState({
        songLength: _.max(_.map(this.state.stems, (stem) => stem.audioLength)),
      }, () => {
        this.logEvent(EVENT_TYPES.STEMS_LOADED)
        googleTagPushEvent(GOOGLE_TAG_EVENTS.STEMS_LOADED)
        fullStoryEvent(GOOGLE_TAG_EVENTS.STEMS_LOADED)
        this.loadComments()
      })
    } else {
      this.setState({
        songLength: _.max(_.map(this.state.stems, (stem) => stem.audioLength))
      }, () => this.loadNextStem())
    }
  }


  /*******************************
  * ANALYZE LOUDNESS
  ********************************/
  analyzeLoudnessLufs(stemId, buffer) {
    if (!this.isSongType(SONG_TYPES.MIX_COMPARISON)) {
      this.setStemState(stemId, {
        loudnessValue: 0.0,
        decibelAdjustment: 0.0,
        normalizedGain: 1.0,
      })
      return null
    }

    const offlineAudioContext = new OfflineAudioContext(
      buffer.numberOfChannels,
      buffer.length,
      buffer.sampleRate
    )

    const source = offlineAudioContext.createBufferSource()
    source.buffer = buffer

    const loudnessMeter = new LoudnessMeter({
      source: source,
      modes: ['integrated'],
      workerUri: window.needlesWorkerUrl
    })

    loudnessMeter.on('dataavailable', (event) => {
      const loudnessValue = _.round(event.data.value, 1)
      const decibelAdjustment = -14.0 - loudnessValue
      const normalizedGain = this.decibelsToGain(decibelAdjustment)
      this.setStemState(stemId, {
        loudnessValue,
        decibelAdjustment,
        normalizedGain,
      })
    })

    loudnessMeter.start()
  }


  /*******************************
  * ANALYTICS
  ********************************/
  logEvent(eventType) {
    try {
      const params = {
        eventType,
        songId: _.get(this, 'props.song.id'),
        artistId: _.get(this, 'props.artist.id'),
      }
      const userId = _.get(this, 'state.currentUser.id')
      if (!_.isNil(userId)) { params.userId = userId }
      analytics(params)
    } catch (e) {
      // no-op
    }
  }

  /*******************************
  * COMMENT LOADING FUNCTIONS
  ********************************/
  loadCommentsRequest() {
    const commentsParams = {}
    if (!_.isNil(this.state.commentsLastTimestamp)) { commentsParams.last_timestamp = this.state.commentsLastTimestamp }
    if (!_.isNil(this.state.commentsPage)) { commentsParams.page = this.state.commentsPage }
    const commentsUrl = setUrlParams(`/api${this.props.song.url}/comments`, commentsParams)

    fetch(commentsUrl).then((res) => res.json(), (error) => {
      fetchPost('/api/client_error/', 'POST', {
        user_id: _.get(this, 'state.currentUser.id'),
        message: `[PlayerApp] - loadCommentsRequest Error: ${_.get(error, 'message')}`,
      })
    }).then((data) => {
      const normalizedData = normalize(data.comments, [COMMENT_SCHEMA])
      const addComments = _.get(normalizedData, 'entities.comments', {})
      this.addCommentsToState(addComments)
      this.setState({
        commentsNumPages: _.get(data, 'numPages', this.state.commentsNumPages),
        commentsPage: _.get(data, 'page', this.state.commentsPage),
      }, () => {
        if (this.state.commentsPage+1 < this.state.commentsNumPages) {
          this.setState({ commentsPage: this.state.commentsPage+1 }, () => this.loadCommentsRequest())
        } else {
          let lastTimestamp = null
          _.each(this.state.comments, (c) => {
            if (_.isNil(lastTimestamp) || moment(c.updatedAt).unix() > moment(lastTimestamp).unix()) {
              lastTimestamp = c.updatedAt
            }
          })
          this.setState({
            isCommentsLoading: false,
            commentsLastTimestamp: lastTimestamp,
          }, () => {
            this.loadCommentsTimeout = setTimeout(() => this.loadComments(), COMMENTS_RELOAD_TIME)
            if (_.get(this, 'state.isFirstCommentsLoad')) {
              this.logEvent(EVENT_TYPES.COMMENTS_LOADED)
              googleTagPushEvent(GOOGLE_TAG_EVENTS.COMMENTS_LOADED)
              fullStoryEvent(GOOGLE_TAG_EVENTS.COMMENTS_LOADED)
              this.setState({ isFirstCommentsLoad: false })
            }
          })
        }
      })
    }, (error) => {
      this.setState({ isCommentsLoading: false })
      fetchPost('/api/client_error/', 'POST', {
        user_id: _.get(this, 'state.currentUser.id'),
        message: `[PlayerApp] - loadCommentsRequest Error: ${_.get(error, 'message')}`,
      })
    })
  }

  loadComments() {
    if (_.get(this.props, 'song.isCommentsDisabled')) { return null }

    this.setState({
      isCommentsLoading: true,
      commentsPage: 0,
      commentsNumPages: 0,
    }, () => this.loadCommentsRequest())
  }

  postStemComment(stemId, postData, callback, errCallback) {
    fetchPost(`/api/stems/${stemId}/comments.json`, 'POST', postData).then((res) => res.json()).then((data) => {
      this.processCommentResponse(data, callback, errCallback, { addToShowComments: true })
    })
  }

  putComment(commentId, postData, callback, errCallback) {
    fetchPost(`/api/comments/${commentId}.json`, 'PUT', postData).then((res) => res.json()).then((data) => {
      this.processCommentResponse(data, callback, errCallback)
    })
  }

  deleteComment(commentId, callback, errCallback) {
    fetchPost(`/api/comments/${commentId}.json`, 'DELETE').then((res) => res.json()).then((data) => {
      this.processCommentResponse(data, callback, errCallback)
    })
  }

  postCommentLike(commentId, callback, errCallback) {
    fetchPost(`/api/comments/${commentId}/like.json`, 'POST', {}).then((res) => res.json()).then((data) => {
      this.processCommentResponse(data, callback, errCallback)
    })
  }

  deleteCommentLike(commentId, callback, errCallback) {
    fetchPost(`/api/comments/${commentId}/like.json`, 'DELETE', {}).then((res) => res.json()).then((data) => {
      this.processCommentResponse(data, callback, errCallback)
    })
  }

  getUserLikes() {
    fetch(`/api${this.props.song.url}/user_likes`).then((res) => res.json()).then((data) => {
      const normalizedData = normalize(data.comments, [COMMENT_SCHEMA])
      const addComments = _.get(normalizedData, 'entities.comments', {})
      this.addCommentsToState(addComments)
    })
  }

  processCommentResponse(data, callback, errCallback, opts={}) {
    if (data.errors) {
      if (!_.isNil(errCallback)) { errCallback(data.errors) }
    } else {
      const commentsToAdd = {}
      const normalizedComment = normalize(data.comment, COMMENT_SCHEMA)
      _.merge(commentsToAdd, normalizedComment.entities.comments)
      if (!_.isNil(data.replyToComment)) {
        const normalizedReplyToComment = normalize(data.replyToComment, COMMENT_SCHEMA)
        _.merge(commentsToAdd, normalizedReplyToComment.entities.comments)
      }
      this.addCommentsToState(commentsToAdd, () => {
        if (opts.addToShowComments && _.isNil(data.replyToComment)) {
          this.setState({
            showCommentIds: _.concat(this.state.showCommentIds, [_.get(data, 'comment.id')]),
          }, () => { if (!_.isNil(callback)) { callback() } })
        } else {
          if (!_.isNil(callback)) { callback() }
        }
      })
    }
  }

  onLogin(postData, callback, errCallback) {
    fetchPost(`/api/login.json`, 'POST', postData).then((res) => res.json()).then((data) => {
      if (data.errors) {
        if (!_.isNil(errCallback)) { errCallback(data.errors) }
      } else {
        const resUser = _.get(data, 'user', {})
        this.setState({ currentUser: resUser }, () => {
          googleTagPushDataLayer({ 'userId': _.get(resUser, 'id') })
          fullStorySetUserVars(resUser)
          this.getUserLikes()
          if (!_.isNil(callback)) { callback() }
        })
      }
    })
  }

  onRefreshUser(callback, errCallback) {
    fetch(`/api/user.json`).then((res) => res.json()).then((data) => {
      if (data.errors) {
        if (!_.isNil(errCallback)) { errCallback(data.errors) }
      } else {
        const resUser = _.get(data, 'user', {})
        this.setState({ currentUser: resUser }, () => {
          googleTagPushDataLayer({ 'userId': _.get(resUser, 'id') })
          fullStorySetUserVars(resUser)
          if (!_.isNil(callback)) { callback() }
        })
      }
    })
  }

  addCommentsToState(comments, callback) {
    const mergedComments = _.reduce(comments, (memo, value, key) => ({
      ...memo,
      [key]: {
        ..._.get(this.state, `comments[${key}]`, {}),
        ...value,
      },
    }), {})
    const deletedCommentIds = _.compact(_.map(comments, (comment) => comment.isDestroyed ? comment.id : null))
    const allComments = { ...this.state.comments, ...mergedComments }
    _.each(deletedCommentIds, (id) => _.unset(allComments, [id]))

    this.setState({ comments: allComments }, () => {
      if (!_.isNil(callback)) { callback() }
    })
  }

  /*******************************
  * INTERFACE INTERACTION FUNCTIONS
  ********************************/
  onPlayPause() {
    // STOP
    if (this.state.isPlaying) {
      this.pause()
      this.logEvent(EVENT_TYPES.PAUSE_ACTION)
      googleTagPushEvent(GOOGLE_TAG_EVENTS.PAUSE_ACTION)
      fullStoryEvent(GOOGLE_TAG_EVENTS.PAUSE_ACTION)
    // PLAY
    } else {
      this.play()
      this.logEvent(EVENT_TYPES.PLAY_ACTION)
      googleTagPushEvent(GOOGLE_TAG_EVENTS.PLAY_ACTION)
      fullStoryEvent(GOOGLE_TAG_EVENTS.PLAY_ACTION)
    }
  }

  onAddCommentClick() {
    let showCommentsPanel = this.state.showCommentsPanel
    if (document.documentElement.clientWidth < MOBILE_BREAKPOINT) {
      showCommentsPanel = false
    }
    this.setState({
      showCommentsPanel: showCommentsPanel,
      isAddCommentState: true,
      addCommentTime: null,
      addCommentStemId: null,
    })
  }

  onCancelAddCommentState() {
    this.setState({ isAddCommentState: false })
  }

  setHighlightedCommentId(commentId) {
    this.setState({ highlightCommentId: commentId })
  }

  clearHighlightedCommentId(commentId) {
    if (this.state.highlightCommentId === commentId) {
      this.setState({ highlightCommentId: null })
    }
  }

  onSeekBarClick(e, stem) {
    const barPos = parseFloat(e.clientX - TRACK_HEADER_WIDTH)
    const clickTime = (barPos/this.getTrackWidth()) * this.state.songLength

    // LOOP CLICK START
    if (this.state.isSetLoopStart) {
      this.setLoopStart(clickTime)
      this.showOverlay(OVERLAY_TYPE.LOOP_CONTROLS)

    // LOOP CLICK END
    } else if (this.state.isSetLoopEnd) {
      this.setLoopEnd(clickTime)
      this.showOverlay(OVERLAY_TYPE.LOOP_CONTROLS)


    // ADD COMMENT CLICK
    } else if (this.state.isAddCommentState) {
      this.setState({
        isAddCommentState: false,
        showCommentsPanel: true,
        addCommentTime: clickTime,
        addCommentStemId: stem.id,
        showCommentIds: [],
        commentPanelType: PANEL_TYPE.ADD_COMMENT,
      })

    // NORMAL CLICK
    } else {
      const wasPlaying = this.state.isPlaying
      if (wasPlaying) { this.pause() }
      this.setState({ currentTime: clickTime }, () => {
        if (wasPlaying) { this.play() }
      })
    }

    this.logEvent(EVENT_TYPES.SEEKBAR_CLICK)
    googleTagPushEvent(GOOGLE_TAG_EVENTS.SEEKBAR_CLICK)
    fullStoryEvent(GOOGLE_TAG_EVENTS.SEEKBAR_CLICK)
  }

  onCommentBubbleClick(stemId, showCommentIds, commentTime) {
    if (this.state.isAddCommentState) {
      this.setState({
        isAddCommentState: false,
        showCommentsPanel: true,
        addCommentTime: commentTime,
        addCommentStemId: stemId,
        showCommentIds: [],
        commentPanelType: PANEL_TYPE.ADD_COMMENT,
      })
    } else {
      this.setState({
        isAddCommentState: false,
        showCommentsPanel: true,
        addCommentTime: null,
        addCommentStemId: null,
        showCommentIds: showCommentIds,
        commentPanelType: PANEL_TYPE.COMMENTS,
      })
    }
    this.logEvent(EVENT_TYPES.COMMENT_BUBBLE_CLICK)
    googleTagPushEvent(GOOGLE_TAG_EVENTS.COMMENT_BUBBLE_CLICK)
    fullStoryEvent(GOOGLE_TAG_EVENTS.COMMENT_BUBBLE_CLICK)
  }

  onCommentButtonClick(stemId) {
    this.setState({
      isAddCommentState: false,
      showCommentsPanel: true,
      addCommentTime: null,
      addCommentStemId: stemId,
      showCommentIds: [],
      commentPanelType: PANEL_TYPE.ADD_COMMENT,
    })
  }

  onCommentTimeClick(time, callback) {
    const wasPlaying = this.state.isPlaying;
    if (wasPlaying) { this.pause() }
    this.setState({ currentTime: time }, () => {

      // IF currently auto-playing, resume play and reset the auto-play timeout
      if (!_.isNil(commentTimeClickAutoplayTimeout)) {
        clearTimeout(commentTimeClickAutoplayTimeout)
        this.play()
        commentTimeClickAutoplayTimeout = setTimeout(() => {
          this.pause()
          commentTimeClickAutoplayTimeout = null
        }, MOBILE_AUTOPLAY_TIMEOUT)

      // IF this was playing (but not playing a snippet), just let it roll
      } else if (wasPlaying) {
        this.play()

      // IF this wasn't playing at all (and we're on mobile), start auto-play
      } else {
        this.play()
        commentTimeClickAutoplayTimeout = setTimeout(() => {
          this.pause()
          commentTimeClickAutoplayTimeout = null
        }, MOBILE_AUTOPLAY_TIMEOUT)
      }
      if (!_.isNil(callback)) { callback() }
    })
  }

  onCommentListenClick(time) {
    this.resetTracks()
    this.onCommentTimeClick(time)
  }

  onCommentListenSoloClick(stemId, time) {
    this.isolateStem(stemId)
    this.onCommentTimeClick(time)
  }

  hideCommentsPanel() {
    this.setState({
      showCommentsPanel: false,
      addCommentTime: null,
      addCommentStemId: null,
      showCommentIds: [],
      highlightCommentId: null,
      commentPanelType: defaultState.commentPanelType,
    })
  }

  toggleCommentsPanel() {
    if (this.state.showCommentsPanel) {
      this.hideCommentsPanel()
    } else {
      this.setState({ showCommentsPanel: true })
    }
    this.logEvent(EVENT_TYPES.COMMENTS_PANEL_BUTTON)
    googleTagPushEvent(GOOGLE_TAG_EVENTS.COMMENTS_PANEL_BUTTON)
    fullStoryEvent(GOOGLE_TAG_EVENTS.COMMENTS_PANEL_BUTTON)
  }

  soloStem(stemId) {
    // MIX COMPARISON SOLO BEHAVIOR
    if (this.isSongType(SONG_TYPES.MIX_COMPARISON)) {
      const soloStem = _.find(this.state.stems, { id: stemId })
      const otherStems = _.filter(this.state.stems, (stem) => (stem.id !== stemId))
      this.setState({
        stems: [
          ..._.map(otherStems, (stem) => ({ ...stem, isSoloed: false })),
          { ...soloStem, isSoloed: true },
        ]
      }, () => this.setAllStemGain())
    // DEFAULT SOLO BEHAVIOR
    } else {
      this.setStemState(stemId, {
        isSoloed: !_.get(_.find(this.state.stems, { id: stemId }), 'isSoloed'),
      }, () => this.setAllStemGain())
    }
    this.logEvent(EVENT_TYPES.STEM_SOLO_CLICK)
    googleTagPushEvent(GOOGLE_TAG_EVENTS.STEM_SOLO_CLICK)
    fullStoryEvent(GOOGLE_TAG_EVENTS.STEM_SOLO_CLICK)
  }

  isolateStem(stemId) {
    this.setState({
      stems: _.map(this.state.stems, (stem) => ({
        ...stem,
        isSoloed: (_.get(stem, 'id') === stemId),
      }))
    }, () => this.setAllStemGain())
  }

  muteUnmuteStem(stem) {
    if (stem.isMuted) {
      this.setStemState(stem.id, { isMuted: false }, () => this.setStemGain(stem.id))
    } else {
      this.setStemState(stem.id, { isMuted: true }, () => this.setStemGain(stem.id))
    }
    this.logEvent(EVENT_TYPES.STEM_MUTE_CLICK)
    googleTagPushEvent(GOOGLE_TAG_EVENTS.STEM_MUTE_CLICK)
    fullStoryEvent(GOOGLE_TAG_EVENTS.STEM_MUTE_CLICK)
  }

  resetTracks() {
    if (this.isSongType(SONG_TYPES.MIX_COMPARISON)) {
      this.setState({
        stems: _.map(this.state.stems, (stem) => ({
          ...stem,
          isSoloed: stem.index === 0,
          isMuted: false,
        }))
      }, () => this.setAllStemGain())
    } else {
      this.setState({
        stems: _.map(this.state.stems, (stem) => ({
          ...stem,
          isSoloed: false,
          isMuted: false,
        }))
      }, () => this.setAllStemGain())
    }

    this.logEvent(EVENT_TYPES.RESET_STEMS_CLICK)
    googleTagPushEvent(GOOGLE_TAG_EVENTS.RESET_STEMS_CLICK)
    fullStoryEvent(GOOGLE_TAG_EVENTS.RESET_STEMS_CLICK)
  }

  onCancelLoopState() {
    this.setState({
      isSetLoopState: false,
      isSetLoopStart: false,
      loopStart: null,
      loopEnd: null,
    })
  }

  setLoopStartState() {
    this.hideOverlay()
    this.setState({
      isSetLoopStart: true,
      loopStart: null,
    })
  }

  setLoopEndState() {
    this.hideOverlay()
    this.setState({
      isSetLoopEnd: true,
      loopEnd: null,
    })
  }


  setLoopStart(time) {
    let loopStart = time
    let loopEnd = this.state.loopEnd
    if (!_.isNil(loopEnd) && loopEnd < loopStart) {
      loopEnd = null
    }

    this.setState({
      loopEnd: loopEnd,
      loopStart: loopStart,
      isSetLoopStart: false,
      isLoopingOn: true,
    })
  }

  setLoopEnd(time) {
    let loopStart = this.state.loopStart
    let loopEnd = time
    if (!_.isNil(loopStart) && loopEnd < loopStart) {
      loopStart = null
    }

    this.setState({
      loopEnd: loopEnd,
      loopStart: loopStart,
      isSetLoopEnd: false,
      isLoopingOn: true,
    })
  }

  setLoopLength() {
    if (!_.isNil(this.state.loopStart)) {
      this.hideOverlay()
      this.setState({
        loopEnd: this.state.loopStart + (60.0 / this.state.userTempo * this.state.loopLengthBeats),
        isLoopingOn: true,
      })
    }
  }

  onCopyLink() {
    this.showOverlay(OVERLAY_TYPE.SHARE_LINK, () => {
      this.shareLinkInput.select()
      document.execCommand('copy')
      setTimeout(() => { this.hideOverlay() }, LINK_COPIED_TIMEOUT)
    })
    this.logEvent(EVENT_TYPES.SHARE_LINK_CLICK)
    googleTagPushEvent(GOOGLE_TAG_EVENTS.SHARE_LINK_CLICK)
    fullStoryEvent(GOOGLE_TAG_EVENTS.SHARE_LINK_CLICK)
  }

  parseKeyDown(e) {
    switch (e.which || e.keyCode) {
      case 32:
        this.onPlayPause()
        break

      case 13:
        this.jumpToTime(0)
        break

      case 49:
        if (this.isSongType(SONG_TYPES.MIX_COMPARISON)) {
          const soloStem = _.find(this.state.stems, { index: 0 })
          if (!_.isNil(soloStem)) { this.soloStem(_.get(soloStem, 'id')) }
        }
        break

      case 50:
        if (this.isSongType(SONG_TYPES.MIX_COMPARISON)) {
          const soloStem = _.find(this.state.stems, { index: 1 })
          if (!_.isNil(soloStem)) { this.soloStem(_.get(soloStem, 'id')) }
        }
        break

      case 51:
        if (this.isSongType(SONG_TYPES.MIX_COMPARISON)) {
          const soloStem = _.find(this.state.stems, { index: 2 })
          if (!_.isNil(soloStem)) { this.soloStem(_.get(soloStem, 'id')) }
        }
        break

      case 52:
        if (this.isSongType(SONG_TYPES.MIX_COMPARISON)) {
          const soloStem = _.find(this.state.stems, { index: 3 })
          if (!_.isNil(soloStem)) { this.soloStem(_.get(soloStem, 'id')) }
        }
        break

      case 53:
        if (this.isSongType(SONG_TYPES.MIX_COMPARISON)) {
          const soloStem = _.find(this.state.stems, { index: 4 })
          if (!_.isNil(soloStem)) { this.soloStem(_.get(soloStem, 'id')) }
        }
        break

      case 54:
        if (this.isSongType(SONG_TYPES.MIX_COMPARISON)) {
          const soloStem = _.find(this.state.stems, { index: 5 })
          if (!_.isNil(soloStem)) { this.soloStem(_.get(soloStem, 'id')) }
        }
        break

      case 55:
        if (this.isSongType(SONG_TYPES.MIX_COMPARISON)) {
          const soloStem = _.find(this.state.stems, { index: 6 })
          if (!_.isNil(soloStem)) { this.soloStem(_.get(soloStem, 'id')) }
        }
        break

      case 56:
        if (this.isSongType(SONG_TYPES.MIX_COMPARISON)) {
          const soloStem = _.find(this.state.stems, { index: 7 })
          if (!_.isNil(soloStem)) { this.soloStem(_.get(soloStem, 'id')) }
        }
        break

      case 57:
        if (this.isSongType(SONG_TYPES.MIX_COMPARISON)) {
          const soloStem = _.find(this.state.stems, { index: 8 })
          if (!_.isNil(soloStem)) { this.soloStem(_.get(soloStem, 'id')) }
        }
        break

      case 58:
        if (this.isSongType(SONG_TYPES.MIX_COMPARISON)) {
          const soloStem = _.find(this.state.stems, { index: 9 })
          if (!_.isNil(soloStem)) { this.soloStem(_.get(soloStem, 'id')) }
        }
        break

      case 59:
        if (this.isSongType(SONG_TYPES.MIX_COMPARISON)) {
          const soloStem = _.find(this.state.stems, { index: 0 })
          if (!_.isNil(soloStem)) { this.soloStem(_.get(soloStem, 'id')) }
        }
        break

      default:
        return
    }
    e.preventDefault()
  }

  onResize(e) {
    this.setState({ clientWidth: document.documentElement.clientWidth })
  }

  /*******************************
  * OVERLAY FUNCTIONS
  ********************************/
  showOverlay(overlayType, callback) {
    this.setState({ showOverlayType: overlayType }, () => {
      setTimeout(() => {
        this.setState({ isOverlayFadedOut: false }, () => {
          setTimeout(() => {
            if (!_.isNil(callback)) { callback() }
          }, OVERLAY_FADE_TIME)
        })
      }, 5)
    })

    switch (overlayType) {
      case OVERLAY_TYPE.HELP:
        this.logEvent(EVENT_TYPES.INFO_OVERLAY_TRIGGER)
        googleTagPushEvent(GOOGLE_TAG_EVENTS.INFO_OVERLAY_TRIGGER)
        fullStoryEvent(GOOGLE_TAG_EVENTS.INFO_OVERLAY_TRIGGER)
        break
      case OVERLAY_TYPE.SETTINGS:
        this.logEvent(EVENT_TYPES.SETTINGS_OVERLAY_TRIGGER)
        googleTagPushEvent(GOOGLE_TAG_EVENTS.SETTINGS_OVERLAY_TRIGGER)
        fullStoryEvent(GOOGLE_TAG_EVENTS.SETTINGS_OVERLAY_TRIGGER)
        break
      case OVERLAY_TYPE.STEM_INFO:
        this.logEvent(EVENT_TYPES.STEM_INFO_OVERLAY)
        googleTagPushEvent(GOOGLE_TAG_EVENTS.STEM_INFO_OVERLAY)
        fullStoryEvent(GOOGLE_TAG_EVENTS.STEM_INFO_OVERLAY)
        break
      case OVERLAY_TYPE.SONG_INFO:
        this.logEvent(EVENT_TYPES.SONG_INFO_OVERLAY)
        googleTagPushEvent(GOOGLE_TAG_EVENTS.SONG_INFO_OVERLAY)
        fullStoryEvent(GOOGLE_TAG_EVENTS.SONG_INFO_OVERLAY)
        break
      default:
        //no-op
        break
    }
  }

  hideOverlay(callback) {
    this.setState({isOverlayFadedOut: true }, () => {
      setTimeout(() => {
        this.setState({
          infoOverlayStemId: null,
          showOverlayType: null,
          addCommentStemId: null,
          showCommentIds: null,
          addCommentTime: null,
        }, () => {
          setTimeout(() => {
            if (!_.isNil(callback)) { callback() }
          }, 5)
        })
      }, OVERLAY_FADE_TIME)
    })
  }


  /*******************************
  * CLOCK FUNCTIONS
  ********************************/
  // Start Clock
  startClock() {
    this.state.lastTimestamp = audioContext.currentTime
    if (this.state.isVarispeedEnabled) {
      this.clockTimeout = window.requestAnimationFrame(() => this.varispeedClockTick())
    } else {
      this.clockTimeout = window.requestAnimationFrame(() => this.clockTick())
    }
  }

  // Clock Tick
  clockTick() {
    if (this.state.isPlaying && !this.state.isVarispeedEnabled) {
      const timeChange = audioContext.currentTime - this.state.lastTimestamp
      this.setState({
        lastTimestamp: audioContext.currentTime,
        currentTime: this.state.currentTime + timeChange,
      }, () => {
        const isLoopingValid = this.state.isLoopingOn && !_.isNil(this.state.loopStart) && !_.isNil(this.state.loopEnd)
        if (isLoopingValid && this.state.loopEnd <= this.state.currentTime) {
          this.pause(() => this.jumpToTime(this.state.loopStart, () => this.play()))
        } else if (this.state.currentTime <= this.state.songLength) {
          this.clockTimeout = window.requestAnimationFrame(() => this.clockTick())
        } else {
          this.pause(() => this.jumpToTime(0))
        }
      })
    }
  }

  varispeedClockTick() {
    if (this.state.isPlaying && this.state.isVarispeedEnabled) {
      const timeChange = audioContext.currentTime - this.state.lastTimestamp
      this.setState({
        lastTimestamp: audioContext.currentTime,
        currentTime: this.state.currentTime + (timeChange * this.state.speed),
      }, () => {
        const isLoopingValid = this.state.isLoopingOn && !_.isNil(this.state.loopStart) && !_.isNil(this.state.loopEnd)
        if (isLoopingValid && this.state.loopEnd <= this.state.currentTime) {
          this.pause(() => this.jumpToTime(this.state.loopStart, () => this.play()))
        } else if (this.state.currentTime <= this.state.songLength) {
          this.clockTimeout = window.requestAnimationFrame(() => this.varispeedClockTick())
        } else {
          this.pause(() => this.jumpToTime(0))
        }
      })
    }
  }

  prepAllStems(callback) {
    this.setState({
      stems: _.map(this.state.stems, (stem) => {
        let source = null
        let gain = null
        if (!stem.isError) {
          source = audioContext.createBufferSource()
          gain = audioContext.createGain()
          source.buffer = stem.buffer
          source.connect(gain)
          source.playbackRate.value = this.state.speed
          gain.connect(audioContext.destination)
          this.setStemGainNode(stem, gain)
        }
        return {
          ...stem,
          gain: gain,
          source: source,
        }
      })
    }, () => { if (!_.isNil(callback)) { callback() } })
  }

  play(callback) {
    this.prepAllStems(() => {
      _.forEach(this.state.stems, (stem) => {
        if (!stem.isError &&
            !_.isNil(stem.source) &&
            stem.isLoaded &&
            stem.audioLength > this.state.currentTime) {
          stem.source.start(0.2, this.state.currentTime)
        }
      })
      this.setState({ isPlaying: true }, () =>{
        this.startClock()
        if (!_.isNil(callback)) { callback() }
      })
    })
  }

  pause(callback) {
    _.forEach(this.state.stems, (stem) => {
      if (!stem.isError &&
          !_.isNil(stem.source) &&
          stem.isLoaded &&
          stem.audioLength > this.state.currentTime) {
        stem.source.stop()
      }
    })
    this.setState({ isPlaying: false }, () => {
      if (!_.isNil(callback)) { callback() }
    })
  }

  jumpToTime(time, callback) {
    const wasPlaying = this.state.isPlaying;
    if (wasPlaying) { this.pause() }
    this.setState({ currentTime: time }, () => {
      if (wasPlaying) { this.play() }
      if (!_.isNil(callback)) { callback() }
    })
  }

  /*******************************
  * RENDER FUNCTIONS
  ********************************/
  getTrackWidth() {
    let trackWidth = this.state.clientWidth - TRACK_HEADER_WIDTH
    if (this.state.showCommentsPanel) { trackWidth -= COMMENTS_PANEL_WIDTH }
    return trackWidth
  }

  getStemComments(stemId) {
    let stemComments = _.filter(_.values(this.getComments()), (c) => c.stemId === stemId)
    return stemComments
  }

  getComments() {
    let allComments = this.state.comments
    if (this.state.isArtistCommentsOnly) { allComments = _.filter(allComments, (c) => c.isOwnerComment) }
    if (this.state.isHideAllComments) { allComments = [] }
    if (Array.isArray(this.state.commentsFilterStemIds) && this.state.commentsFilterStemIds.length !== 0) {
      allComments = _.filter(allComments, (c) => this.state.commentsFilterStemIds.includes(c.stemId))
    }
    return allComments
  }

  renderOverlay(type, classes, children, dismissOnClick) {
    if (this.state.showOverlayType === type) {
      const overlayMaskOnClick = () => {
        if (dismissOnClick) { this.hideOverlay() }
      }
      return (
        <div
          className={classnames(classes, 'overlay', { 'faded-out': this.state.isOverlayFadedOut })}
          onClick={overlayMaskOnClick}
        >
          <div className='overlay-box' onClick={(e) => e.stopPropagation()}>
            {children}
          </div>
        </div>
      )
    }
  }

  renderHeader() {
    return (
      <React.Fragment>
        <span className='header-text'>
          <strong>
            {!_.isEmpty(_.get(this.props, 'song.description')) ? (
              <a className='name-button' onClick={() => this.showOverlay(OVERLAY_TYPE.SONG_INFO)}>
                {this.props.song.name}
              </a>
            ) : this.props.song.name}
          </strong>
        </span>
        <span className='header-text ml-2'>
          -&nbsp;
          <a className='name-button' href={_.get(this, 'props.artist.publicUrl')}>
            {_.get(this, 'props.artist.artistName')}
          </a>
        </span>
      </React.Fragment>
    )
  }

  renderTime() {
    let currentTime = ""
    let totalTime = ""
    if (this.state.songLength > 0) {
      currentTime = secondsToMinutes(this.state.currentTime)
      totalTime = secondsToMinutes(this.state.songLength)
    }

    return (
      <div className='header-time px-3'>
        {currentTime}<span className='d-none d-sm-inline'>&nbsp;/&nbsp;{totalTime}</span>
      </div>
    )
  }

  renderButtons() {
    const isCommentsViewable = this.isCommentsViewable()
    if (this.state.isAddCommentState) {
      return (
        <React.Fragment>
          <div className="flex-grow-1">
            <div className='control-text'>
              Click where you would like to add your comment
            </div>
          </div>
          <div className="flex-grow-1">
            <div onClick={() => this.onCancelAddCommentState()} className='control-button'>cancel</div>
          </div>
        </React.Fragment>
      )
    } else if (this.state.isSetLoopStart || this.state.isSetLoopEnd) {
      return (
        <React.Fragment>
          <div className="flex-grow-1">

            {this.state.isSetLoopStart ? (
              <div className='control-text'>
                Click where you would like the loop to begin
              </div>
            ) : (
              <div className='control-text'>
                Click where you would like the loop to end
              </div>
            )}
          </div>
          <div className="flex-grow-1">
            <div onClick={() => this.onCancelLoopState()} className='control-button'>cancel</div>
          </div>
        </React.Fragment>
      )
    } else {
      return (
        <React.Fragment>
          <div className='flex-grow-1'>
            <a onClick={() => this.onPlayPause()} className='control-button'>
              {this.state.isPlaying ? (
                <i className='fa fa-pause'></i>
              ) : (
                <i className='fa fa-play'></i>
              )}
            </a>
          </div>
          <div className='flex-grow-1'>
            <a onClick={() => this.showOverlay(OVERLAY_TYPE.HELP)} className='control-button'><i className='fa fa-info'></i></a>
          </div>
          <div className='flex-grow-1'>
            <a onClick={() => this.showOverlay(OVERLAY_TYPE.SETTINGS)} className='control-button'><i className='fa fa-cog'></i></a>
          </div>
          {isCommentsViewable && (
            <div className='flex-grow-1'>
              <a onClick={() => this.toggleCommentsPanel()} className='control-button' >
                <i className='fa fa-comments'></i>
              </a>
            </div>
          )}
          {!this.isSongType(SONG_TYPES.MIX_COMPARISON) && (
            <div className='flex-grow-1'>
              <a onClick={() => this.resetTracks()} className='control-button'><i className="fa fa-undo"></i></a>
            </div>
          )}
          <div className='flex-grow-1 d-none d-md-block'>
            <a onClick={() => this.showOverlay(OVERLAY_TYPE.LOOP_CONTROLS)} className='control-button'><i className="fa fa-retweet"></i></a>
          </div>
        </React.Fragment>
      )
    }
  }

  render() {
    const infoOverlayStem = _.find(this.state.stems, { id: this.state.infoOverlayStemId })

    return (
      <React.Fragment>
        <div className='control-bar fixed-top w-100 d-flex d-sm-none flex-column align-items-center py-3 px-0 px-sm-4'>
          <div className='flex-grow-1 flex-shrink-1 d-flex flex-row align-items-center mb-2 mb-sm-0 w-100'>
            <div className='flex-grow-1 flex-shrink-1 d-flex control-header text-sm-center px-3'>
              {this.renderHeader()}
            </div>
            <div className="flex-grow-0 flex-shrink-0">
              {this.renderTime()}
            </div>
          </div>

          <div className="flex-grow-0 d-flex flex-row align-items-center w-100">
            {this.renderButtons()}
          </div>
        </div>

        <div className='control-bar fixed-top w-100 d-none d-sm-flex flex-row align-items-center py-3 px-0 px-sm-4'>
          <div className='flex-grow-1 flex-shrink-1 d-flex control-header text-sm-center px-3'>
            {this.renderHeader()}
          </div>

          <div className="flex-grow-0 flex-shrink-0">
            {this.renderTime()}
          </div>

          <div className="flex-grow-0 d-flex align-items-center w-xs-100">
            {this.renderButtons()}
          </div>
        </div>

        <div className='spacer-top'></div>

        <div className='d-flex flex-row'>
          <div className='flex-grow-1 flex-shrink-0'>
            {_.map(_.orderBy(this.state.stems, 'index'), (stem) => (
              <Stem
                key={stem.key}
                stem={stem}
                comments={this.getStemComments(stem.id)}
                songLength={this.state.songLength}
                songType={_.get(this.props, 'song.songType')}
                currentTime={this.state.currentTime}
                trackWidth={this.getTrackWidth()}
                loopStart={this.state.loopStart}
                loopEnd={this.state.loopEnd}
                isLoopingOn={this.state.isLoopingOn}
                addCommentTime={this.state.addCommentTime}
                addCommentStemId={this.state.addCommentStemId}
                isAddCommentState={this.state.isAddCommentState}
                isVolumeNormalized={this.state.isVolumeNormalized}
                showCommentIds={this.state.showCommentIds}
                highlightCommentId={this.state.highlightCommentId}
                isSoloActive={this.isSoloActive()}
                logEvent={(e) => this.logEvent(e)}
                onNameClick={() => this.setState({ infoOverlayStemId: stem.id }, () => this.showOverlay(OVERLAY_TYPE.STEM_INFO))}
                onMuteButton={() => this.muteUnmuteStem(stem)}
                onSeekBarClick={(e) => this.onSeekBarClick(e, stem)}
                onSoloButton={() => this.soloStem(stem.id)}
                onCommentButtonClick={() => this.onCommentButtonClick(stem.id)}
                onCommentBubbleClick={(commentIds, time) => this.onCommentBubbleClick(stem.id, commentIds, time)}
                onStemReload={() => this.reloadStem(stem.id)}
              />
            ))}
          </div>
          {this.state.showCommentsPanel && (<div className='flex-grow-0 flex-shrink-0 comments-spacer'></div>)}
        </div>

        <div className='clear'></div>

        <div className='spacer-bottom'></div>
        <div className='d-flex flex-row bottom-bar'>
          <div className='flex-grow-1 left-bar'>
            <div className='d-flex flex-column text-align-container small-text'>
              <div className='flex-grow-1'></div>
              <div className='flex-shrink-0'>
                {window.isMobile ? (
                  <React.Fragment></React.Fragment>
                ) : (
                  <React.Fragment>Create your own stem player at&nbsp;</React.Fragment>
                )}
                <a href="/">
                  <img src={getStaticAsset('logoColor')} className="inline-logo" />&nbsp;
                  splitter.fm&nbsp;
                  <i className="fa fa-copyright"></i>&nbsp;
                </a>
              </div>
              <div className='flex-grow-1'></div>
            </div>
          </div>

          <div className='flex-grow-1 right-bar'>
            {!_.isNil(this.props.song.prevUrl) && (
              <a className='bottom-button' href={this.props.song.prevUrl}>
                <i className='fa fa-arrow-left'></i>
              </a>
            )}
            <a className='bottom-button half-margin-left' onClick={() => this.onCopyLink()} href='#'>
              <i className='fa fa-share-square-o'></i>
            </a>
            <a className='bottom-button half-margin-left' href={this.props.song.artistUrl}>
              <i className='fa fa-home'></i>
            </a>
            {!_.isNil(this.props.song.nextUrl) && (
              <a className='bottom-button half-margin-left' href={this.props.song.nextUrl}>
                <i className='fa fa-arrow-right'></i>
              </a>
            )}
          </div>
        </div>

        {this.renderOverlay(OVERLAY_TYPE.NO_STEMS, null, (
          <React.Fragment>
            <strong>This song has no stems!</strong><br/>
            Stems may still be uploading, so try coming back later.<br/>
            <a href={_.get(this, 'props.artist.publicUrl')}>Click here to return to the artist page</a>.
          </React.Fragment>
        ))}

        {this.renderOverlay(OVERLAY_TYPE.SHARE_LINK, null, (
          <React.Fragment>
            <div className='mb-2'>
              <strong>Link Successfully Copied to Clipboard!</strong><br/>
              Just hit paste to share this song with your friends!<br/>
            </div>
            <div className="form-group mb-0">
              <input
                className="share-url form-control mb-0" type="text"
                ref={(ref) => this.shareLinkInput = ref}
                value={this.props.song.fullPublicUrl}
                readOnly
              />
            </div>
          </React.Fragment>
        ), true)}

        {this.renderOverlay(OVERLAY_TYPE.MOBILE, 'info-overlay', (
          <MobileOverlay onCloseOverlay={() => this.hideOverlay(() => this.loadNextStem())} />
        ), false)}

        {this.renderOverlay(OVERLAY_TYPE.SONG_INFO, 'info-overlay', (
          <DescriptionOverlay
            title={_.get(this.props, 'song.name')}
            subtitle='Song Details'
            description={_.get(this.props, 'song.description')}
            onCloseOverlay={() => this.hideOverlay()}
          />
        ), true)}

        {this.renderOverlay(OVERLAY_TYPE.STEM_INFO, 'info-overlay', (
          <DescriptionOverlay
            title={_.get(infoOverlayStem, 'name')}
            subtitle='Stem Details'
            description={_.get(infoOverlayStem, 'description')}
            onCloseOverlay={() => this.hideOverlay()}
          />
        ), true)}

        {this.renderOverlay(OVERLAY_TYPE.SETTINGS, 'info-overlay', (
          <SettingsOverlay
            volume={this.state.volume}
            speed={this.state.speed}
            stems={this.state.stems}
            songType={_.get(this.props, 'song.songType')}
            isVolumeNormalized={this.state.isVolumeNormalized}
            isArtistCommentsOnly={this.state.isArtistCommentsOnly}
            isHideAllComments={this.state.isHideAllComments}
            isDownloadable={_.get(this.props, 'song.isDownloadable', false)}
            onVolumeChange={(volume) => this.onVolumeChange(volume)}
            onSpeedChange={(speed) => this.onSpeedChange(speed)}
            onSpeedReset={() => this.onSpeedReset()}
            onArtistCommentsOnlyChange={(value) => this.setState({ isArtistCommentsOnly: value })}
            onHideAllCommentsChange={(value) => this.setState({ isHideAllComments: value })}
            onIsVolumeNormalizedChange={(value) => this.onVolumeNormalizeChange(value)}
            onCloseOverlay={() => this.hideOverlay()}
          />
        ), true)}

        {this.renderOverlay(OVERLAY_TYPE.HELP, 'info-overlay', (
          <InfoOverlay onCloseOverlay={() => this.hideOverlay()} />
        ), true)}

        {this.renderOverlay(OVERLAY_TYPE.LOOP_CONTROLS, 'info-overlay', (
          <LoopControlsOverlay
            isLoopingOn={this.state.isLoopingOn}
            loopStart={this.state.loopStart}
            loopEnd={this.state.loopEnd}
            loopLengthBeats={this.state.loopLengthBeats}
            userTempo={this.state.userTempo}
            onSetLoopStart={() => this.setLoopStartState()}
            onSetLoopEnd={() => this.setLoopEndState()}
            onSetLoopStartCurrentTime={() => this.setLoopStart(this.state.currentTime)}
            onSetLoopEndCurrentTime={() => this.setLoopEnd(this.state.currentTime)}
            onSetLoopLength={() => this.setLoopLength()}
            onResetTempo={() => this.setState({ userTempo: _.get(this.props, 'song.tempo', 0) })}
            onSetUserTempo={(tempo) => this.setState({ userTempo: tempo })}
            onSetLoopLengthBeats={(beats) => this.setState({ loopLengthBeats: beats })}
            onLoopEnabledChange={(value) => this.setState({ isLoopingOn: value })}
            onCloseOverlay={() => this.hideOverlay()}
          />
        ), true)}

        {this.state.showCommentsPanel && (
          <div className='d-flex flex-column side-panel-container' onClick={(e) => this.hideCommentsPanel()}>
            <div className='flex-grow-0 flex-shrink-0 spacer-top'></div>
            <div className='flex-grow-1 flex-shrink-1 h-100 side-panel' onClick={(e) => e.stopPropagation()}>
              <CommentsPanel
                song={this.props.song}
                stems={this.state.stems}
                currentUser={this.state.currentUser}
                onLogin={(postData, callback, errCallback) => this.onLogin(postData, callback, errCallback)}
                onRefreshUser={(callback, errCallback) => this.onRefreshUser(callback, errCallback)}
                comments={this.getComments()}
                currentTime={this.state.currentTime}
                songLength={this.state.songLength}
                panelType={this.state.commentPanelType}
                panelSortType={this.state.commentPanelSortType}
                showCommentIds={this.state.showCommentIds}
                addCommentTime={this.state.addCommentTime}
                addCommentStem={_.find(this.state.stems, { id: this.state.addCommentStemId })}
                isCommentsDisabled={this.isCommentsDisabled()}
                isArtistCommentsOnly={this.state.isArtistCommentsOnly}
                clearShowComments={() => this.setState({ showCommentIds: [] })}
                clearAddComment={() => this.setState({ addCommentTime: null, addCommentStemId: null, })}
                onCommentTimeClick={(time) => this.onCommentTimeClick(time)}
                onCommentListenClick={(time) => this.onCommentListenClick(time)}
                onCommentListenSoloClick={(stemId, time) => this.onCommentListenSoloClick(stemId, time)}
                onCloseComments={() => this.hideCommentsPanel()}
                onAddComment={() => this.onAddCommentClick()}
                onIsolateStem={(stemId) => this.isolateStem(stemId)}
                onArtistCommentsOnlyChange={(value) => this.setState({ isArtistCommentsOnly: value })}
                onCommentsFilterStemIdsChange={(value) => this.setState({ commentsFilterStemIds: value })}
                commentsFilterStemIds={this.state.commentsFilterStemIds}
                updateCommentPanelType={(panelType, callback) => this.updateCommentPanelType(panelType, callback)}
                updateCommentPanelSortType={(sortType, callback) => this.updateCommentPanelSortType(sortType, callback)}
                setHighlightedCommentId={(commentId) => this.setHighlightedCommentId(commentId)}
                clearHighlightedCommentId={(commentId) => this.clearHighlightedCommentId(commentId)}
                postStemComment={(stemId, postData, callback, errCallback) =>
                  this.postStemComment(stemId, postData, callback, errCallback)}
                putComment={(commentId, postData, callback, errCallback) =>
                  this.putComment(commentId, postData, callback, errCallback)}
                deleteComment={(commentId, callback, errCallback) =>
                  this.deleteComment(commentId, callback, errCallback)}
                postCommentLike={(commentId, callback, errCallback) =>
                  this.postCommentLike(commentId, callback, errCallback)}
                deleteCommentLike={(commentId, callback, errCallback) =>
                  this.deleteCommentLike(commentId, callback, errCallback)}
                logEvent={(eventType) => this.logEvent(eventType)}
              />
            </div>
            <div className='flex-grow-0 flex-shrink-0 spacer-bottom'></div>
          </div>
        )}

        {this.state.isCommentsLoading && (
          <div className='comments-loading'>
            <span className='fa fa-clock-o'></span> loading
          </div>
        )}
      </React.Fragment>
    )
  }
}
