import React from 'react'
import { getStaticAsset } from 'utils'

export default ({ onCloseOverlay }) => {
  return (
    <div>
      <div className='clearfix mb-2'>
        <strong>Hi there!</strong>
        <a className='float-close' onClick={() => onCloseOverlay()}><i className="fa fa-times"></i></a>
      </div>
      <div className='mb-3'>
        <img src={getStaticAsset("logoColor")} className="inline-logo inline-logo-large mr-2" />
        splitter.fm hasn&#39;t been optimized for mobile devices yet (we&#39;re working on it), so we recommend checking these songs out on a computer. Also, if you're getting an error while loading the stems on your phone, exit out of any extra browser tabs you have open and that may help.
      </div>
      <div className='mb-3'>
        <i className="fa fa-volume-up" /> Make sure to enable sound on your mobile device to hear the stems played through your device&#39;s speakers.
      </div>

      <div className='mb-3'>
        Feel free to tap the <i className="fa fa-share-square-o"></i> icon in the bottom right to automatically copy this song&#39;s URL.  You can then text or email it to yourself to open up on a computer (you can also send it to your friends)!
      </div>

      <button className='btn btn-info' onClick={() => onCloseOverlay()}>Continue to Song</button>
    </div>
  )
}
