import React from 'react'
import classnames from 'classnames'

import { TRACK_HEADER_WIDTH } from 'components/player/PlayerApp'

import { doesSongTypeMatch } from 'utils'
import { SONG_TYPES } from 'static'

import {
  googleTagPushEvent,
  fullStoryEvent,
  EVENT_TYPES,
  GOOGLE_TAG_EVENTS
} from 'utils/analytics'

const TRACK_HEIGHT = 70 // px
const PIXELS_PER_COMMENT = 24 // px
const COMMENT_BUBBLE_SIZE = 15 // px
const TOOLTIP_HEIGHT = 300 // px
const TOOLTIP_WIDTH = 250 // px
const MAX_COMMENT_BUBBLE_LENGTH = 160


const defaultState = {
  showCommentTooltip: false,
  commentTooltip: {
    username: null,
    message: null,
    commentCount: 0,
    xPos: 0,
    yPos: 0,
  }
}

export default class Stem extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      ...defaultState
    }
  }

  groupComments() {
    const trackWidth = _.get(this.props, 'trackWidth', 0)
    const songLength = _.get(this.props, 'songLength', 0)
    if (songLength === 0 || trackWidth === 0) { return {} }

    const pixelsPerSecond = parseFloat(trackWidth)/parseFloat(songLength)
    const secondsGrouped = _.ceil(PIXELS_PER_COMMENT/pixelsPerSecond)
    const groupedComments = {}
    _.each(this.props.comments, (comment) => {
      if (comment.replyToCommentId !== null) { return null }
      const groupedTime = _.floor(comment.stemTime/secondsGrouped)*secondsGrouped
      if (_.isNil(groupedComments[groupedTime])) {
        groupedComments[groupedTime] = []
      }

      groupedComments[groupedTime].push(comment)
    })

    _.each(_.keys(groupedComments), (groupKey) => {
      groupedComments[groupKey] = _.orderBy(groupedComments[groupKey], ['isOwnerComment', 'numLikes', 'createdAt'], ['desc', 'desc', 'desc'])
    })

    return groupedComments
  }

  showHoverComment(e, topComment, numComments) {
    if (window.isMobile) { return null }
    let xPos = e.target.offsetLeft + COMMENT_BUBBLE_SIZE
    let yPos = e.target.offsetTop + COMMENT_BUBBLE_SIZE
    let isAbove = false

    if (e.clientX > (window.innerWidth/2)) {
      xPos = xPos - TOOLTIP_WIDTH - COMMENT_BUBBLE_SIZE
    }

    if (e.clientY > (window.innerHeight/2)) {
      yPos = yPos - TOOLTIP_HEIGHT - COMMENT_BUBBLE_SIZE
      isAbove = true
    }

    let message = topComment.message
    if (message.length > MAX_COMMENT_BUBBLE_LENGTH) {
      message = `${_.join(_.slice(topComment.message, 0, MAX_COMMENT_BUBBLE_LENGTH), "")}...`
    }

    this.setState({
      showCommentTooltip: true,
      commentTooltip: {
        username: topComment.username,
        message: message,
        commentCount: numComments,
        isAbove,
        yOffset: 0,
        xPos,
        yPos,
      },
    })

    if (!_.isNil(this.props.logEvent)) {
      this.props.logEvent(EVENT_TYPES.COMMENT_BUBBLE_HOVER)
      googleTagPushEvent(GOOGLE_TAG_EVENTS.COMMENT_BUBBLE_HOVER)
      fullStoryEvent(GOOGLE_TAG_EVENTS.COMMENT_BUBBLE_HOVER)
    }
  }

  hideHoverComment(e, callback) {
    this.setState({ ...defaultState, }, () => {
      if (!_.isNil(callback)) { callback() }
    })
  }

  onCommentBubbleClick(e, showCommentIds, time) {
    this.hideHoverComment(e, () => this.props.onCommentBubbleClick(showCommentIds, time))
  }

  renderComments() {
    const stem = _.get(this.props, 'stem', {})
    const trackWidth = _.get(this.props, 'trackWidth', 0)
    const songLength = _.get(this.props, 'songLength', 0)
    const groupedComments = this.groupComments()
    return _.map(_.keys(groupedComments), (key) => {
      const commentPosition = ((parseFloat(trackWidth)/songLength)*key) + TRACK_HEADER_WIDTH
      const commentGroup = groupedComments[key]
      const numComments = commentGroup.length
      const showCommentIds = _.map(commentGroup, (comment) => comment.id)
      const topComment = _.first(commentGroup)
      const propsShowCommentIds = _.get(this.props, 'showCommentIds', [])
      const highlightCommentId = _.get(this.props, 'highlightCommentId', null)
      const isSelected = _.intersection(showCommentIds, propsShowCommentIds).length > 0
      const isHighlighted = _.indexOf(showCommentIds, highlightCommentId) >= 0
      return (
        <div
          key={`stem-${stem.id}-comment-${key}`}
          className={classnames('comment-bubble', {
            'big': isHighlighted,
            'highlighted': isSelected,
          })}
          style={{ left: `${commentPosition}px` }}
          onClick={(e) => this.onCommentBubbleClick(e, showCommentIds, key)}
          onMouseEnter={(e) => this.showHoverComment(e, topComment, numComments)}
          onMouseLeave={(e) => this.hideHoverComment(e)}
        ></div>
      )
    })
  }

  renderAddComment() {
    const addCommentTime = _.get(this.props, 'addCommentTime')
    const addCommentStemId = _.get(this.props, 'addCommentStemId')
    if (_.isNil(addCommentTime) || addCommentStemId !== _.get(this.props, 'stem.id')) { return null }
    const trackWidth = _.get(this.props, 'trackWidth', 0)
    const songLength = _.get(this.props, 'songLength', 0)
    const commentPosition = ((parseFloat(trackWidth)/songLength)*addCommentTime) + TRACK_HEADER_WIDTH
    return (
      <div className='comment-bubble highlighted' style={{ left: `${commentPosition}px` }} ></div>
    )
  }

  renderHoverComment() {
    if (!this.state.showCommentTooltip) { return null }
    const commentTooltip = _.get(this.state, 'commentTooltip', {})

    let yPos = commentTooltip.yPos
    if (commentTooltip.isAbove && commentTooltip.yOffset > 0) {
      yPos = commentTooltip.yPos + (TOOLTIP_HEIGHT - commentTooltip.yOffset)
    }

    return (
      <div className='d-flex comment-tooltip' ref={(el) => {
        if (!_.isNil(el) && el.clientHeight !== this.state.commentTooltip.yOffset) {
          this.setState({
            commentTooltip: {
              ...this.state.commentTooltip,
              yOffset: el.clientHeight,
            }
          })
        }
      }} style={{ top: `${yPos}px`, left: `${commentTooltip.xPos}px` }}>
        <div className='d-flex flex-column content'>
          {commentTooltip.username && (
            <div className='flex-grow-0 flex-shrink-0'>
              <strong>@{commentTooltip.username}</strong>
            </div>
          )}
          <div className='d-flex flex-grow-1 flex-shrink-0 comment-text prewrap'>
            {commentTooltip.message}
          </div>
          <div className='d-flex separator my-2'></div>
          {commentTooltip.commentCount > 1 && (
            <div className='flex-grow-0 flex-shrink-0'>
              <i>{commentTooltip.commentCount} comments</i>
            </div>
          )}
          <div className='flex-grow-0 flex-shrink-0'>
            <i>Click bubble to read more</i>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const stem = _.get(this.props, 'stem', {})
    const trackWidth = _.get(this.props, 'trackWidth', 0)
    const currentTime = _.get(this.props, 'currentTime', 0)
    const songLength = _.get(this.props, 'songLength', 0)
    const loopStart = _.get(this.props, 'loopStart')
    const loopEnd = _.get(this.props, 'loopEnd')

    let seekWidth = '0px'
    let loopStartOffset = '-1000px'
    let loopStartWidth = '0px'
    let loopEndOffset = '-1000px'
    let loopEndWidth = '0px'

    if (songLength > 0) {
      const songPercent = (parseFloat(currentTime) / songLength)
      seekWidth = (songPercent * trackWidth) + 'px'

      if (!_.isNil(loopStart)) {
        const loopStartPercent = (parseFloat(loopStart) / songLength)
        const loopStartNum = (loopStartPercent * trackWidth)
        loopStartOffset = `80px`
        loopStartWidth = `${loopStartNum}px`
      }

      if (!_.isNil(loopEnd)) {
        const loopEndPercent = (parseFloat(loopEnd) / songLength)
        const loopEndNum = (loopEndPercent * trackWidth) + TRACK_HEADER_WIDTH
        const loopEndWidthNum = trackWidth + TRACK_HEADER_WIDTH - loopEndNum
        loopEndOffset = `${loopEndNum}px`
        loopEndWidth = `${loopEndWidthNum}px`
      }
    }

    const isMuted = this.props.isSoloActive ? !stem.isSoloed : stem.isMuted

    if (stem.isError) {
      return (
        <div className='track muted'>
          <div className='track-header'>
            <div className='track-buttons'>
              <div className='track-button error' onClick={() => this.props.onStemReload()}>
                reload
              </div>
            </div>
          </div>
          <div className='track-audio' style={{backgroundColor: stem.bgColor}}>
            <div className='track-seek-bar' style={{
              width: seekWidth,
            }}></div>
            <div className="waveform-line" style={{
              width: `${trackWidth}px`,
              height: `${(TRACK_HEIGHT/2)+1}px`,
            }}></div>

            <div className="waveform-click-target" onClick={(e) => this.props.onSeekBarClick(e)} style={{
              width: `${trackWidth}px`,
              height: `${TRACK_HEIGHT}px`,
            }}></div>
          </div>
        </div>
      )
    }

    return (
      <div className={classnames('track', { muted: isMuted })}>
        <div className='track-header'>
          <div className='track-title'>
            {stem.isLoaded ? (
              doesSongTypeMatch(this.props.songType, SONG_TYPES.MIX_COMPARISON) ? (
                <span>
                  {!_.isEmpty(stem.description) ? (
                    <a className='name-button' onClick={() => this.props.onNameClick()}>
                      {this.props.isVolumeNormalized ? stem.decibelAdjustment : 0.0} dB
                    </a>
                  ) : `${this.props.isVolumeNormalized ? stem.decibelAdjustment : 0.0} dB` }
                </span>
              ) : (
                <span>
                  {!_.isEmpty(stem.description) ? (
                    <a className='name-button' onClick={() => this.props.onNameClick()}>
                      {stem.name}
                    </a>
                  ) : `${stem.name}` }
                </span>
              )
            ) : (
              <span>
                <div className="spinner-border spinner-border-sm text-light" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </span>
            )}
          </div>
          <div className='track-buttons'>
            {_.get(this.props, 'isAddCommentState') ? (
              <div className='track-button comment' onClick={() => this.props.onCommentButtonClick()}>
                at play
              </div>
            ) : (
              <React.Fragment>
                <div className='track-button left mute' onClick={() => this.props.onMuteButton()}>
                  M
                </div>
                <div className={classnames('track-button solo', { 'solo-activated': stem.isSoloed })} onClick={() => this.props.onSoloButton()}>
                  S
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
        <div className='track-audio' style={{backgroundColor: stem.bgColor}}>
          {doesSongTypeMatch(this.props.songType, SONG_TYPES.MIX_COMPARISON) && (
            <div className='track-title-overlay'>{stem.name}</div>
          )}
          <div className='track-seek-bar' style={{
            width: seekWidth,
          }}></div>
          {this.props.isLoopingOn && (
            <>
              <div className='loop-marker loop-marker-start' style={{
                left: loopStartOffset,
                width: loopStartWidth,
              }}></div>
              <div className='loop-marker loop-marker-end' style={{
                left: loopEndOffset,
                width: loopEndWidth,
              }}></div>
            </>
          )}
          <img
            className={classnames("waveform-image", { 'd-none': !stem.isLoaded })}
            src={stem.imageUrl}
            style={{
              width: `${(parseFloat(trackWidth)*(stem.audioLength/songLength))}px`,
              height: `${TRACK_HEIGHT}px`,
            }}
          />
          <div className="waveform-line" style={{
            width: `${trackWidth}px`,
            height: `${(TRACK_HEIGHT/2)+1}px`,
          }}></div>

          {this.renderComments()}
          {this.renderAddComment()}

          <div className="waveform-click-target" onClick={(e) => this.props.onSeekBarClick(e)} style={{
            width: `${trackWidth}px`,
            height: `${TRACK_HEIGHT}px`,
          }}></div>
        </div>
        {this.renderHoverComment()}
      </div>
    )
  }
}
