import React from 'react'
import classnames from 'classnames'
import moment from 'moment'

import Comment from 'components/player/Comment'
import CommentsFilterAndSort from 'components/player/CommentsFilterAndSort'
import { secondsToMinutes } from 'utils/time'

import { iff } from 'utils'

import {
  googleTagPushEvent,
  fullStoryEvent,
  EVENT_TYPES,
  GOOGLE_TAG_EVENTS
} from 'utils/analytics'

const SCROLL_OFFSET = 110 // px
export const PANEL_TYPE = {
  LOGIN: 'login',
  COMMENTS: 'comments',
  ADD_COMMENT: 'addComment',
  EDIT_COMMENT: 'editComment',
}

export const PANEL_SORT_TYPE = {
  RECENT: 'recent', // Sort by created_at timestamp
  MOST_POPULAR: 'mostPopular', // Sort by # of likes and replies
  SONG_ORDER: 'songOrder', // Sort chronologically on song by stem_time
}

const defaultFormValues = {
  messageValue: '',
  editCommentId: null,
  errors: {},
  scrollToCommentId: null,
}

const defaultState = {
  scrollToCommentId: null,
  isLoading: false,
  replyToCommentId: null,
  commentFormRedirectPanelType: null,
  loginRedirectPanelType: null,
  loginForm: {
    email: '',
    password: '',
    save_login: false,
  },
  ...defaultFormValues,
}

export default class CommentsPanel extends React.Component {

  constructor(props) {
    super(props)
    this.state = { ...defaultState }

    this.containerRef = null
    this.focusInputRef = null
    this.blockKeyListener = (e) => e.stopPropagation()
  }

  componentDidMount() {
    this.containerRef.addEventListener('keydown', this.blockKeyListener)
    this.setFocusInput()
  }

  componentWillUnmount() {
    this.containerRef.removeEventListener('keydown', this.blockKeyListener)
  }

  updateLoginForm(update) {
    this.setState({
      loginForm: {
        ...this.state.loginForm,
        ...update,
      }
    })
  }

  setFocusInput() {
    if (!_.isNil(this.focusInputRef)) { this.focusInputRef.focus() }
  }

  setLoading(isLoading, callback) {
    this.setState({ isLoading }, () => iff(callback))
  }

  isUserLoggedIn() {
    return !_.isNil(_.get(this.props, 'currentUser.id'))
  }

  isUserConfirmed() {
    return _.get(this.props, 'currentUser.isEmailConfirmed', false)
  }

  isCommentEditable(comment) {
    return _.get(this.props, 'currentUser.id') === comment.userId
  }

  isCommentDeletable(comment) {
    const currentUser = _.get(this.props, 'currentUser', {})
    const song = _.get(this.props, 'song', {})
    return currentUser.id === comment.userId ||
           currentUser.artistId === song.artistId ||
           currentUser.isAdmin
  }

  isShowAllComments() {
    return _.isEmpty(_.get(this.props, 'showCommentIds', []))
  }

  isShowCommentReplies() {
    return !_.isNil(this.state.replyToCommentId);
  }

  getReplyToComment() {
    return _.find(this.props.comments, { id: this.state.replyToCommentId })
  }

  getStem(stemId) {
    return _.find(_.get(this.props, 'stems', []), { id: stemId })
  }

  getStems() {
    return _.get(this.props, 'stems', [])
  }

  getAddCommentStem() {
    return this.getStem(this.getAddCommentStemId())
  }

  getAddCommentStemId() {
    const addCommentStemId = _.get(this.props, 'addCommentStem.id')
    const showCommentIds = _.get(this.props, 'showCommentIds', [])
    if (!_.isNil(addCommentStemId)) {
      return addCommentStemId
    } else if (!_.isEmpty(showCommentIds)) {
      const firstComment = _.find(_.get(this.props, 'comments'), { id: _.first(showCommentIds) })
      return _.get(firstComment, 'stemId')
    } else if (this.isShowCommentReplies()) {
      return _.get(this.getReplyToComment(), 'stemId')
    } else {
      return null
    }
  }

  getAddCommentTime() {
    const currentTime = _.get(this.props, 'currentTime', 0)
    const addCommentTime = _.get(this.props, 'addCommentTime')
    if (this.isShowCommentReplies()) {
      return this.getReplyToComment().stemTime
    } else if (!this.isShowAllComments()) {
      return _.min(_.map(this.getComments(), (c) => c.stemTime))
    } else if (!_.isNil(addCommentTime)) {
      return addCommentTime
    } else {
      return currentTime
    }
  }

  onCommentListenClick(time) {
    this.props.onCommentListenClick(time)
    this.props.logEvent(EVENT_TYPES.COMMENT_STEM_TIME_CLICK)
    googleTagPushEvent(GOOGLE_TAG_EVENTS.COMMENT_STEM_TIME_CLICK)
    fullStoryEvent(GOOGLE_TAG_EVENTS.COMMENT_STEM_TIME_CLICK)
  }

  onCommentListenSoloClick(stemId, time) {
    this.props.onCommentListenSoloClick(stemId, time)
    this.props.logEvent(EVENT_TYPES.COMMENT_STEM_SOLO_CLICK)
    googleTagPushEvent(GOOGLE_TAG_EVENTS.COMMENT_STEM_SOLO_CLICK)
    fullStoryEvent(GOOGLE_TAG_EVENTS.COMMENT_STEM_SOLO_CLICK)
  }

  onAddComment() {
    this.props.onAddComment()
    this.props.logEvent(EVENT_TYPES.ADD_COMMENT_CLICK)
    googleTagPushEvent(GOOGLE_TAG_EVENTS.ADD_COMMENT_CLICK)
    fullStoryEvent(GOOGLE_TAG_EVENTS.ADD_COMMENT_CLICK)
  }

  onSeeAllComments() {
    this.props.clearShowComments()
  }

  onLoginPanel() {
    const savePanelType = this.props.panelType
    this.props.updateCommentPanelType(PANEL_TYPE.LOGIN, () => {
      this.setState({ loginRedirectPanelType: savePanelType },
        () => this.setFocusInput())
    })
  }

  onLoginCancel() {
    this.props.updateCommentPanelType(this.state.loginRedirectPanelType, () => {
      this.setState({
        loginRedirectPanelType: null,
        loginForm: { ...defaultState.loginForm },
        errors: {},
      })
    })
  }

  onLoginSubmit() {
    this.props.onLogin({
      user: { ...this.state.loginForm }
    }, () => this.onLoginCancel(), (errors) => { this.setState({ errors }) })
  }

  onCommentEdit(comment) {
    const savePanelType = this.props.panelType
    this.props.updateCommentPanelType(PANEL_TYPE.EDIT_COMMENT, () => {
      this.setState({
        commentFormRedirectPanelType: savePanelType,
        editCommentId: comment.id,
        messageValue: comment.message,
      }, () => this.setFocusInput())
    })
  }

  onCommentFormCancel() {
    this.props.clearAddComment()
    const newPanelType = this.state.commentFormRedirectPanelType || PANEL_TYPE.COMMENTS
    this.props.updateCommentPanelType(newPanelType, () => {
      this.setState({
        commentFormRedirectPanelType: null,
        ...defaultFormValues,
      })
    })
  }

  onCommentLike(comment) {
    if (this.isUserLoggedIn()) {
      this.setLoading(true, () => {
        this.props.postCommentLike(
          comment.id,
          () => this.setLoading(false),
          () => this.setLoading(false)
        )
      })
    } else {
      this.onLoginPanel()
    }
    this.props.logEvent(EVENT_TYPES.LIKE_COMMENT_CLICK)
    googleTagPushEvent(GOOGLE_TAG_EVENTS.LIKE_COMMENT_CLICK)
    fullStoryEvent(GOOGLE_TAG_EVENTS.LIKE_COMMENT_CLICK)
  }

  onCommentUnlike(comment) {
    this.setLoading(true, () => {
      this.props.deleteCommentLike(
        comment.id,
        () => this.setLoading(false),
        () => this.setLoading(false)
      )
    })
  }

  onCommentReplies(comment) {
    this.setState({ replyToCommentId: comment.id }, () => this.setFocusInput())
    this.props.logEvent(EVENT_TYPES.REPLY_COMMENT_CLICK)
    googleTagPushEvent(GOOGLE_TAG_EVENTS.REPLY_COMMENT_CLICK)
    fullStoryEvent(GOOGLE_TAG_EVENTS.REPLY_COMMENT_CLICK)
  }

  onCommentDelete(comment) {
    this.setLoading(true, () => {
      this.props.deleteComment(
        comment.id,
        () => this.setLoading(false),
        () => this.setLoading(false)
      )
    })
  }

  onHoverComment(commentId) {
    this.props.setHighlightedCommentId(commentId)
  }

  onExitHoverComment(commentId) {
    this.props.clearHighlightedCommentId(commentId)
  }

  onReplyBack() {
    const replyToComment = this.getReplyToComment()
    this.setState({
      scrollToCommentId: replyToComment.id,
      replyToCommentId: replyToComment.replyToCommentId,
    }, () => {
      if (!_.isNil(this.state.scrollToCommentId) && !_.isNil(this.scrollToCommentRef)) {
        this.scrollToCommentRef.scrollIntoView()
        this.scrollToCommentRef = null
        this.setState({ scrollToCommentId: null })
      }
    })
  }

  submitComment() {
    const errorCallback = (errors) => this.setState({ errors, isLoading: false })
    const successCallback = () => {
      this.props.clearAddComment()
      if (this.isShowCommentReplies()) {
        this.setState({ isLoading: false, ...defaultFormValues })
      } else {
        this.setState({ isLoading: false }, () => this.onCommentFormCancel())
      }
    }

    const addCommentStemId = this.getAddCommentStemId()
    if (_.isNil(this.state.editCommentId) && !_.isNil(addCommentStemId)) {
      this.setState({ isLoading: true }, () => {
        this.props.postStemComment(addCommentStemId, {
          comment: {
            message: this.state.messageValue,
            stem_time: _.round(this.getAddCommentTime()),
            reply_to_comment_id: this.state.replyToCommentId,
          }
        }, successCallback, errorCallback)
      })
      this.props.logEvent(EVENT_TYPES.COMMENT_SUBMIT)
      googleTagPushEvent(GOOGLE_TAG_EVENTS.COMMENT_SUBMIT)
      fullStoryEvent(GOOGLE_TAG_EVENTS.COMMENT_SUBMIT)
    } else {
      this.props.putComment(this.state.editCommentId, {
        comment: { message: this.state.messageValue }
      }, successCallback, errorCallback)
    }
  }

  getComments() {
    const allComments = _.get(this.props, 'comments', {})
    const showCommentIds = _.get(this.props, 'showCommentIds', [])
    let showComments = []
    if (!_.isEmpty(showCommentIds)) {
      showComments = _.filter(allComments, (c) => _.indexOf(showCommentIds, c.id) >= 0)
    } else {
      showComments = _.filter(allComments, (c) => c.replyToCommentId === null)
    }
    switch (_.get(this.props, 'panelSortType')) {
      case PANEL_SORT_TYPE.MOST_POPULAR:
        return _.orderBy(showComments, [(comment) => comment.numLikes + comment.numReplies, 'stemTime', 'isOwnerComment', 'createdAt'], ['desc', 'asc', 'desc', 'desc'])
      case PANEL_SORT_TYPE.RECENT:
        return _.orderBy(showComments, ['createdAt', 'stemTime', 'isOwnerComment', 'numLikes'], ['desc', 'asc', 'desc', 'desc'])
      case PANEL_SORT_TYPE.SONG_ORDER:
      default:
        return _.orderBy(showComments, ['stemTime', 'isOwnerComment', 'numLikes', 'createdAt'], ['asc', 'desc', 'desc', 'desc'])
    }
  }

  renderCommentsPanel() {
    const comments = this.getComments()
    if (comments.length === 0) { return null }
    return (
      <div className='flex-grow-1 comments-content'>
        <div className='comments-list d-flex flex-column scrollable'>
          {_.map(comments, (comment) => (
            <Comment
              comment={comment}
              stem={this.getStem(comment.stemId)}
              key={`comment-${comment.id}`}
              isCurrentUserComment={_.get(this.props, 'currentUser.id') === comment.userId}
              onCommentListenClick={(time) => this.onCommentListenClick(time)}
              onCommentListenSoloClick={(stemId, time) => this.onCommentListenSoloClick(stemId, time)}
              onCommentLike={() => this.onCommentLike(comment)}
              onCommentUnlike={() => this.onCommentUnlike(comment)}
              onCommentReplies={() => this.onCommentReplies(comment)}
              onCommentDelete={() => this.onCommentDelete(comment)}
              onCommentEdit={() => this.onCommentEdit(comment)}
              isCommentsDisabled={this.props.isCommentsDisabled}
              isEditable={this.isCommentEditable(comment)}
              isDeletable={this.isCommentDeletable(comment)}
              isLoading={this.state.isLoading}
              onHoverComment={() => this.onHoverComment(comment.id)}
              onExitHoverComment={() => this.onExitHoverComment(comment.id)}
              ref={(ref) => {
                if (comment.id === this.state.scrollToCommentId) {
                  this.scrollToCommentRef = ref
                }
              }}
            />
          ))}
          {!this.isShowAllComments() && (
            <button
              className='btn btn-secondary expanded no-margin'
              onClick={() => this.onSeeAllComments()}
            >See All Comments</button>
          )}
        </div>
      </div>
    )
  }

  renderRepliesPanel() {
    const replyToComment = this.getReplyToComment()
    const replies = _.filter(this.props.comments, (c) => c.replyToCommentId === this.state.replyToCommentId)
    return (
      <React.Fragment>
        <div className='flex-grow-0 flex-shrink-0 comments-header'>
          <a className='link block mb-2' onClick={() => this.onReplyBack()}>&lt; Back</a>
        </div>
        <div className='flex-grow-1 comments-content'>
          <div className='comments-list d-flex flex-column scrollable'>
            <Comment
              comment={replyToComment}
              stem={this.getStem(replyToComment.stemId)}
              onCommentListenClick={(time) => this.onCommentListenClick(time)}
              onCommentListenSoloClick={(stemId, time) => this.onCommentListenSoloClick(stemId, time)}
              onCommentLike={() => this.onCommentLike(replyToComment)}
              onCommentUnlike={() => this.onCommentUnlike(replyToComment)}
              onCommentEdit={() => this.onCommentEdit(replyToComment)}
              isCommentsDisabled={this.props.isCommentsDisabled}
              isEditable={this.isCommentEditable(replyToComment)}
              isLoading={this.state.isLoading}
              isHighlighted
            />
            <div className='indented-list'>
              {_.map(replies, (comment) => (
                <Comment
                  comment={comment}
                  stem={this.getStem(comment.stemId)}
                  key={`comment-${comment.id}`}
                  onCommentTimeClick={(time) => this.onCommentTimeClick(time)}
                  isolateStem={(stemId) => this.onCommentIsolateStem(stemId)}
                  onCommentLike={() => this.onCommentLike(comment)}
                  onCommentUnlike={() => this.onCommentUnlike(comment)}
                  onCommentDelete={() => this.onCommentDelete(comment)}
                  onCommentEdit={() => this.onCommentEdit(comment)}
                  isCommentsDisabled={this.props.isCommentsDisabled}
                  isEditable={this.isCommentEditable(comment)}
                  isDeletable={this.isCommentDeletable(comment)}
                  isLoading={this.state.isLoading}
                  hideStemDetails
                />
              ))}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderErrors() {
    if (_.isEmpty(this.state.errors)) { return null }
    return (
      <div className='callout alert text-danger half-margin-bottom half-padding'>
        {_.map(_.keys(this.state.errors), (errorKey) => _.map(this.state.errors[errorKey], (error, i) => (
          <div key={`field-${errorKey}-${i}`}>{errorKey} {error}</div>
        )))}
      </div>
    )
  }

  renderCommentForm() {
    if (this.isUserLoggedIn()) {
      return (
        <div className='flex-grow-1 comments-content'>
          {this.renderErrors()}
          <div className="form-group mb-0">
            <textarea
              ref={(ref) => this.focusInputRef = ref}
              name='message'
              className='comment-text-input form-control'
              placeholder='Write your comment here'
              value={this.state.messageValue}
              onChange={(e) => this.setState({ messageValue: e.target.value })}
            />
          </div>
          <div className='btn-group btn-block m-0'>
            <button className='btn btn-secondary' onClick={() => this.onCommentFormCancel()}>Cancel</button>
            <button
              className='btn btn-info'
              disabled={this.state.isLoading}
              onClick={() => this.submitComment()}
            >Submit</button>
          </div>
        </div>
      )
    } else {
      return (
        <div className='flex-grow-1 comments-content'>
          <div className='flex-grow-1 flex-shrink-0'>
            {!_.isNil(this.state.commentFormRedirectPanelType) && (
              <a className='link block mb-2' onClick={() => this.onCommentFormCancel()}>&lt; Back</a>
            )}
            {this.renderLoginWarning()}
          </div>
        </div>
      )
    }
  }

  renderCommentReplyForm() {
    return (
      <React.Fragment>
        {this.renderErrors()}
        <div className="form-group mb-0">
          <textarea
            ref={(ref) => this.focusInputRef = ref}
            name='message'
            className='comment-text-input form-control'
            placeholder='Write your comment here'
            value={this.state.messageValue}
            onChange={(e) => this.setState({ messageValue: e.target.value })}
          />
        </div>
        <button
          className='btn btn-block btn-info m-0'
          onClick={() => this.submitComment()}
          disabled={this.state.isLoading}
        >Reply</button>
      </React.Fragment>
    )
  }

  renderLoginPanel() {
    return (
      <div className='flex-grow-1 comments-content'>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              ref={(ref) => this.focusInputRef = ref}
              type='email' name='email'
              className="form-control"
              value={this.state.loginForm.email}
              onChange={(e) => this.updateLoginForm({ email: e.target.value })}
            />
          </div>

          <div className="form-group">
            <label> Password </label>
            <input
              type='password' name='password'
              className="form-control"
              value={this.state.loginForm.password}
              onChange={(e) => this.updateLoginForm({ password: e.target.value })}
            />
          </div>

          <div className="form-check">
            <input
              type='checkbox' name='save_login'
              className='form-check-input'
              checked={this.state.loginForm.save_login}
              onChange={(e) => this.updateLoginForm({ save_login: e.target.checked })}
            />
            <label className="form-check-label" htmlFor="save_login">Save Login</label>
          </div>

          {this.renderErrors()}
          <div className='btn-group btn-block m-0'>
            <a className='btn btn-secondary' onClick={() => this.onLoginCancel()}>Cancel</a>
            <button
              className='btn btn-info'
              onClick={() => this.onLoginSubmit()}
              disabled={this.state.isLoading}
            >Login</button>
          </div>
        </form>
      </div>
    )
  }

  renderLoginWarning() {
    return (
      <div className='alert alert-warning smaller-text m-0'>
        <p className='mb-2'>
          To add a comment, you must log in to splitter.fm.
        </p>

        <div className='btn-group btn-block'>
          <button className='btn btn-secondary' onClick={() => this.onLoginPanel()}>Log In</button>
          <a className='btn btn-info' href="/register">Sign Up</a>
        </div>
      </div>
    )
  }

  renderConfirmEmailWarning() {
    return (
      <div className='alert alert-warning smaller-text m-0'>
        <p className='mb-2'>
          To add a comment, you must confirm your email address.  Please check your email for a confirmation link.
        </p>

        <p className='mb-2'>
          If you believe you have already confirmed your email address, click here to refresh your account.
        </p>

        <button className='btn btn-block btn-info' onClick={() => this.props.onRefreshUser()}>Refresh Status</button>
      </div>
    )
  }

  renderHeaderText() {
    const stem = this.getAddCommentStem()
    switch (_.get(this.props, 'panelType')) {
      case PANEL_TYPE.LOGIN:
        return (
          <React.Fragment>
            <div className='big'>Login</div>
          </React.Fragment>
        )
      case PANEL_TYPE.ADD_COMMENT:
        return (
          <React.Fragment>
            <div className='big'>Add Comment</div>
            <div className='small mb-2'>
              Comment on <strong>{_.get(stem, 'name')}</strong> at <strong>{secondsToMinutes(_.round(this.getAddCommentTime()))}</strong>
            </div>
          </React.Fragment>
        )
      case PANEL_TYPE.EDIT_COMMENT:
        return (
          <React.Fragment>
            <div className='big'>Edit comment</div>
          </React.Fragment>
        )
      case PANEL_TYPE.COMMENTS:
      default:
        return (
          <React.Fragment>
            {!this.isShowCommentReplies() ? (
              this.isShowAllComments() ? (
                <div className='big'>All Comments</div>
              ) : (
                <div className='big'>Selected Comments</div>
              )
            ) : (
              <div className='big'>Comment Replies</div>
            )}
          </React.Fragment>
        )
    }
  }

  renderContent() {
    switch (_.get(this.props, 'panelType')) {
      case PANEL_TYPE.LOGIN:
        return this.renderLoginPanel()
      case PANEL_TYPE.ADD_COMMENT:
        return this.renderCommentForm()
      case PANEL_TYPE.EDIT_COMMENT:
        return this.renderCommentForm()
      case PANEL_TYPE.COMMENTS:
      default:
        if (!this.isShowCommentReplies()) {
          return this.renderCommentsPanel()
        } else {
          return this.renderRepliesPanel()
        }
    }
  }

  render() {
    const panelType = _.get(this.props, 'panelType', null)

    return (
      <div className='d-flex flex-column h-100 comments-panel-interior' ref={(ref) => this.containerRef = ref}>
        <div className='d-flex flex-row flex-grow-0 flex-shrink-0 comments-header'>
          <div className='flex-grow-1'>
            {this.renderHeaderText()}
          </div>
          <a className='flex-grow-0 close' onClick={() => this.props.onCloseComments()}><i className="fa fa-times"></i></a>
        </div>

        {panelType === PANEL_TYPE.COMMENTS && this.isShowAllComments() && !this.isShowCommentReplies() && (
            <CommentsFilterAndSort
                onArtistCommentsOnlyChange={(e) => this.props.onArtistCommentsOnlyChange(e)}
                isArtistCommentsOnly={this.props.isArtistCommentsOnly}
                onCommentsFilterStemIdsChange={(e) => this.props.onCommentsFilterStemIdsChange(e)}
                commentsFilterStemIds={this.props.commentsFilterStemIds}
                panelSortType={this.props.panelSortType}
                updateCommentPanelSortType={this.props.updateCommentPanelSortType}
                stems={this.getStems()}
            />
        )}
        {this.renderContent()}

        {panelType === PANEL_TYPE.COMMENTS && !this.props.isCommentsDisabled && (
          <div className='flex-grow-0 flex-shrink-0'>
            {!this.isUserLoggedIn() ? this.renderLoginWarning() : (
              !this.isUserConfirmed() ? this.renderConfirmEmailWarning() : (
                this.isShowCommentReplies() ? (
                   this.renderCommentReplyForm()
                ) : (
                  <button className='btn btn-block btn-info no-margin' onClick={() => this.onAddComment()}>
                    Add Comment
                  </button>
                )
              )
            )}
          </div>
        )}
      </div>
    )
  }
}
