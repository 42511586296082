import React from 'react'

export default ({ onCloseOverlay }) => {
  return (
    <div>
      <div className='clearfix mb-2'>
        <strong>Instructions</strong>
        <a className='float-close' onClick={() => onCloseOverlay()}><i className="fa fa-times"></i></a>
      </div>
      <ul>
        <li>Use the <strong>space bar</strong> or the <i className='fa fa-play'></i>/<i className='fa fa-pause'></i> button in the top bar to play or pause the song (once the audio has loaded).</li>
        <li>Click on the <i className='fa fa-volume-up'></i> button under the name of a stem to mute or unmute a stem.</li>
        <li>Click the <strong>S</strong> button under the name of a stem to hear only that stem. (<strong>S</strong> stands for solo).</li>
        <li>Click anywhere on a waveform to jump to that part of the song.</li>
        <li>Press the <strong>Enter</strong> key to return to the beginning of the song.</li>
        <li>Use the <strong>reset</strong> button to unmute and un-solo all of the stems.</li>
        <li>Use the <i className='fa fa-comments'></i> button to open the comments panel.</li>
        <li>Use the <i className='fa fa-cog'></i> button to open the settings panel. The settings panel allows you to do the following:</li>
        <ul>
          <li>Change the volume.</li>
          <li>Show only comments from the artist.</li>
          <li>Disable all comments.</li>
          <li>Change the playback speed.</li>
        </ul>
      </ul>
    </div>
  )
}
