import React from 'react'

import { doesSongTypeMatch } from 'utils'
import { SONG_TYPES } from 'static'

export default ({
  stems,
  volume,
  speed,
  songType,
  isVarispeedEnabled,
  isVolumeNormalized,
  isArtistCommentsOnly,
  isHideAllComments,
  isDownloadable,
  onVolumeChange,
  onIsVolumeNormalizedChange,
  onSpeedChange,
  onSpeedReset,
  onArtistCommentsOnlyChange,
  onHideAllCommentsChange,
  onCloseOverlay,
}) => {
  const volumeVal = parseInt(parseFloat(volume) * 100.0, 10)
  const speedVal = parseInt(parseFloat(speed) * 100.0, 10)
  return (
    <div className='p-2'>
      <div className='clearfix mb-2'>
        <strong>Settings</strong>
        <a className='float-close' onClick={() => onCloseOverlay()}><i className="fa fa-times"></i></a>
      </div>

      <div className="mt-2 mb-3">
        <div className='row'>
          <div className='col-12 col-sm-6'>
            <div className="form-group">
              <label htmlFor="volume">Volume</label>
              <input
                className='volume-control form-control-range'
                name="volume"
                type="range"
                min="0"
                max="100"
                value={volumeVal}
                onChange={(e) => onVolumeChange(e.target.value)}
              />
            </div>
          </div>

          <div className='col-12 col-sm-6'>
            <div className='form-group'>
              <label htmlFor="speed">
                Playback Speed ({speedVal}%)
                <a className='ml-2 blue' onClick={() => onSpeedReset()}>(reset)</a>
              </label>
              <input
                className='volume-control form-control-range'
                name="speed"
                type="range"
                min="50"
                max="200"
                value={speedVal}
                onChange={(e) => onSpeedChange(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-12 col-sm-6'>
            {doesSongTypeMatch(songType, SONG_TYPES.MIX_COMPARISON) && (
              <div className="form-check">
                <input
                  className="form-check-input"
                  name="is_volume_normalized"
                  type="checkbox"
                  checked={isVolumeNormalized}
                  onChange={(e) => onIsVolumeNormalizedChange(e.target.checked)}
                />
                <label htmlFor="is_volume_normalized" className="form-check-label">Normalize Mix Volume</label>
              </div>
            )}

            <div className="form-check">
              <input
                className="form-check-input"
                name="is_artist_comments_only"
                type="checkbox"
                checked={isArtistCommentsOnly}
                onChange={(e) => onArtistCommentsOnlyChange(e.target.checked)}
                disabled={isHideAllComments}
              />
              <label htmlFor="is_artist_comments_only" className="form-check-label">Artist Comments Only</label>
            </div>
          </div>
          <div className='col-12 col-sm-6'>
            <div className="form-check">
              <input
                className="form-check-input"
                name="is_hide_all_comments"
                type="checkbox"
                checked={isHideAllComments}
                onChange={(e) => onHideAllCommentsChange(e.target.checked)}
              />
              <label htmlFor="is_hide_all_comments" className="form-check-label">Hide All Comments</label>
            </div>
          </div>
        </div>
      </div>

      {isDownloadable && (
        <div>
          <div className="mb-2">
            <strong>Download Stems</strong>
          </div>
          <div className='list-group'>
            {_.map(_.sortBy(stems, 'index'), (stem) => (
              <a key={`${stem.id}-stem-download`} className='list-group-item list-group-item-action py-2 px-3'
                 href={stem.audioDownloadUrl} download={`${_.get(stem, 'name')}.mp3`} target="_blank"
              ><i className="fa fa-download"></i> {_.get(stem, 'name')}</a>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
