import React from 'react'
import Linkify from 'react-linkify'

export default ({ title, subtitle, description, onCloseOverlay }) => {
  return (
    <div>
      <div className='clearfix mb-2'>
        <a className='float-close' onClick={() => onCloseOverlay()}><i className="fa fa-times"></i></a>
        <h4>{title}</h4>
        <strong>{subtitle}</strong>
      </div>
      <Linkify properties={{ target: "_blank" }}>
        <p className='prewrap break-word'>{description}</p>
      </Linkify>
    </div>
  )
}
