import React, { useState } from 'react'
import _ from 'lodash'

export default ({
  onCloseOverlay,
  isLoopingOn,
  loopStart,
  loopEnd,
  loopLengthBeats,
  userTempo,
  onLoopEnabledChange,
  onSetLoopStart,
  onSetLoopEnd,
  onSetLoopStartCurrentTime,
  onSetLoopEndCurrentTime,
  onSetLoopLength,
  onSetLoopLengthBeats,
  onResetTempo,
  onSetUserTempo,
}) => {

  return (
    <div className='p-2'>
      <div className='clearfix mb-2'>
        <strong>Loop Controls</strong>
        <a className='float-close' onClick={() => onCloseOverlay()}><i className="fa fa-times"></i></a>
      </div>

      <div className="mt-2 mb-3">
        <div className="form-check mb-2">
          <input
            className="form-check-input"
            name="is_loop_enabled"
            id="is_loop_enabled"
            type="checkbox"
            checked={isLoopingOn}
            onChange={(e) => onLoopEnabledChange(e.target.checked)}
          />
          <label htmlFor="is_loop_enabled" className="form-check-label">Enable Looping</label>
        </div>

        <div className='mb-3'>
          <button className='btn btn-primary mr-2' onClick={onSetLoopStart}>Set Loop Start</button>
          <button className='btn btn-info' onClick={onSetLoopStartCurrentTime}>Set Loop Start to Current Time</button>
        </div>

        <div className='mb-3'>
          <button className='btn btn-primary mr-2' onClick={onSetLoopEnd}>Set Loop End</button>
          <button className='btn btn-info' onClick={onSetLoopEndCurrentTime}>Set Loop End to Current Time</button>
        </div>

        <div className="form-group mb-3">
          <label htmlFor="tempo">Loop Tempo (BPM)</label>
          <div className='input-group'>
            <input
              className='form-control'
              name="tempo"
              type="number"
              min="1"
              value={userTempo}
              onChange={(e) => onSetUserTempo(e.target.value)}
            />
            <div className='input-group-append'>
              <button
                className='btn btn-info'
                onClick={() => onResetTempo()}>Reset Tempo</button>
            </div>
          </div>
        </div>

        <div className="form-group mb-3">
          <label htmlFor="loop_length">Loop Length (in beats)</label>
          <div className='input-group'>
            <input
              className='form-control'
              name="loop_length"
              type="number"
              min="1"
              value={loopLengthBeats}
              onChange={(e) => onSetLoopLengthBeats(e.target.value)}
            />
            <div className='input-group-append'>
              <button
                className='btn btn-info'
                disabled={_.isNil(loopStart)}
                onClick={() => onSetLoopLength()}>Set Loop Length</button>
            </div>
          </div>
        </div>

        <div className='mb-3'>
        </div>
      </div>
    </div>
  )
}
